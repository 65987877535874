import React, { useEffect, useState } from 'react';
import { Container, Card } from 'react-bootstrap';
import { getExamsByLanguage } from '../../services/apiService';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useAuth } from '../../contexts/AuthContext';

export default function Exams() {
  const { categoryId } = useParams();
  const { user } = useAuth();
  const { t } = useTranslation(['dashboard']);
  const [exams, setExams] = useState([]);

  useEffect(() => {
    document.title = t('headers:exams');
  }, [])

  useEffect(() => {
    fetchExams()
  }, [i18next.language])

  const fetchExams = async () => {
    const exams = await getExamsByLanguage(i18next.language, user.moduleId, categoryId);
    setExams(exams)
  }

  return (
    <Container>
      <h1 className='text-center mb-4'>{t('dashboard:exam-list')}</h1>
      {exams.map((exam, index) => (
        <Link style={{ textDecoration: 'none' }} key={exam.id} to={`/questions/${exam.id}`}>
          <Card style={{ margin: '10px', backgroundColor: index % 2 === 1 ? '#f0f0f0' : "white" }}>
            <Card.Body>
              <Card.Title style={{ marginLeft: 20 }}>
                {exam.name}
              </Card.Title>
            </Card.Body>
          </Card>
        </Link>
      ))}
    </Container>
  );
};
