export const USER_ROLES = {
  ADMIN : 1,
  COURSEADMIN: 2,
  USER: 3,
  SORU: 4,
  DEMO: 5,
}

export const MODULES = {
  MTSK: 1,
  SRC: 2,
}

export const PAYMENT_TYPES = {
  LANG : 1,
  MODULE: 2,
}

export const isAdmin = (role) => {
  return role === USER_ROLES.ADMIN
}

export const isSoru = (role) => {
  return role === USER_ROLES.SORU
}

export const decodeToken = () => {
  const token = localStorage.getItem("token")
  if (!token) return null
  return JSON.parse(atob(token.split('.')[1]))
}

export const ERROR_CODES = {
  ERR_00001: { key: "ERR_00001", value: "server_error" },
  ERR_00002: { key: "ERR_00002", value: "controller_error" },
  ERR_00010: { key: "ERR_00010", value: "token_expired" },
  ERR_00030: { key: "ERR_00030", value: "check_input_fields" },
  ERR_00040: { key: "ERR_00040", value: "wrong_password_or_username" },
  ERR_00041: { key: "ERR_00041", value: "user_not_found" },
  ERR_00042: { key: "ERR_00042", value: "user_passive" },
  ERR_00043: { key: "ERR_00043", value: "user_overflow" },
}

export const getUserRole = (role) => {
  switch (role) {
    case USER_ROLES.ADMIN:
      return "Admin"
    case USER_ROLES.COURSEADMIN:
      return "Kurs Admini"
    case USER_ROLES.USER:
      return "Kullanıcı"
    case USER_ROLES.SORU:
      return "Admin"
    case USER_ROLES.DEMO:
      return "Demo"
    default:
      return "Unknown"
  }
}

export const getUserModule = (module) => {
  switch (module) {
    case MODULES.MTSK:
      return "MTSK"
    case MODULES.SRC:
      return "SRC"
    default:
      return "Unknown"
  }
}

export const getPaymentType = (type) => {
  switch (type) {
    case PAYMENT_TYPES.LANG:
      return "Dil"
    case PAYMENT_TYPES.MODULE:
      return "Modül"
    default:
      return "Unknown"
  }
}