import React, { useEffect, useState } from "react";
import { Form, Button, Card, Container, Row, Col } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { styles } from "./styles";
import { createPayment } from "../../services/paymentServices";
import { getLanguages, getModules, getPaymentPrices } from "../../services/apiService";
import { PAYMENT_TYPES } from "../../helper/constants";

const Pay = () => {
  const { user } = useAuth();
  const [paymentForm, setPaymentForm] = useState({
    price: "2000.0",
    paidPrice: "2000.0",
    installment: 1,
    currency: "TRY",
    paymentChannel: "WEB",
    paymentGroup: "SUBSCRIPTION",
    basketId: "B67832",
    conversationId: "123456789",
    locale: "tr",
  });
  const [paymentCard, setPaymentCard] = useState({
    cardHolderName: "",
    cardNumber: "",
    expireYear: "",
    expireMonth: "",
    cvc: "",
  });
  const [buyer, setBuyer] = useState({
    id: user.course.id.toString(),
    name: user.firstName,
    surname: user.lastName,
    ip: "85.34.78.112",
    email: "",
    gsmNumber: "",
    identityNumber: "",
    city: "",
    country: "",
    registrationAddress: "",
  });
  const [billingAddress, setBillingAddress] = useState({
    address: "",
    city: "",
    country: "",
    contactName: user.firstName + " " + user.lastName,
  });
  const [shippingAddress, setShippingAddress] = useState({
    address: "",
    city: "",
    country: "",
    contactName: user.firstName + " " + user.lastName,
  });
  const [basketItems, setBasketItems] = useState([
    {
      id: "1",
      name: "Subscription",
      category1: "Subscription",
      itemType: "VIRTUAL",
      price: "2000.0",
    },
  ]);
  const [paymentCost, setPaymentCost] = useState(0);
  const [languages, setLanguages] = useState([]);
  const [modules, setModules] = useState([]);
  
  useEffect(() => {
    fetchPaymentPrices();
  }, []);

  const fetchPaymentPrices = async () => {
    const response = await getPaymentPrices();
    const languages = response.filter((item) => item.type === PAYMENT_TYPES.LANG);
    const modules = response.filter((item) => item.type === PAYMENT_TYPES.MODULE);
    setModules(modules);
    setLanguages(languages);
  };

  const handlePaymentSubmit = async (e) => {
    e.preventDefault();
    buyer.gsmNumber = "+90" + buyer.gsmNumber;
    const paymentData = {
      locale: paymentForm.locale,
      conversationId: paymentForm.conversationId,
      price: paymentCost.toString() + ".0",
      paidPrice: paymentCost.toString() + ".0",
      installment: paymentForm.installment,
      basketId: paymentForm.basketId,
      paymentCard,
      buyer,
      shippingAddress,
      billingAddress,
      basketItems,
      currency: paymentForm.currency,
    };

    const response = await createPayment(paymentData);
    console.log("Ödeme işlemi başarılı mı?", response)
    console.log("Ödeme bilgileri:", paymentData);
  };

  const isPaymentFormValid = () => {
    return (
      paymentCard.cardHolderName !== "" &&
      paymentCard.cardNumber !== "" &&
      paymentCard.expireYear !== "" &&
      paymentCard.expireMonth !== "" &&
      paymentCard.cvc !== ""
    );
  };

  const handleCheckboxChange = (e) => {
    setPaymentCost((prev) => prev + parseFloat(e.target.value));
  };

  return (
    <Container>
      <Row>
        <Col>
          <Row>
            <Col>
              <Card style={styles.card}>
                <Card.Body>
                  <h2>Alıcı Bilgileri</h2>
                  <Form>
                    <Form.Group controlId="buyerName">
                      <Form.Label>Ad</Form.Label>
                      <Form.Control
                        type="text"
                        value={buyer.name}
                        onChange={(e) =>
                          setBuyer({
                            ...buyer,
                            name: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    <Form.Group controlId="buyerSurname">
                      <Form.Label>Soyad</Form.Label>
                      <Form.Control
                        type="text"
                        value={buyer.surname}
                        onChange={(e) =>
                          setBuyer({
                            ...buyer,
                            surname: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    <Form.Group controlId="buyerEmail">
                      <Form.Label>E-Posta</Form.Label>
                      <Form.Control
                        type="email"
                        value={buyer.email}
                        onChange={(e) =>
                          setBuyer({
                            ...buyer,
                            email: e.target.value,
                          })
                        }
                        onBlur={(e) => {
                          const email = e.target.value;
                          const emailRegex =
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                          if (!emailRegex.test(email)) {
                            document.getElementById("emailError").innerText =
                              "Lütfen geçerli bir e-posta adresi girin.";
                          } else {
                            document.getElementById("emailError").innerText =
                              "";
                          }
                        }}
                      />
                      <div id="emailError" style={{ color: "red" }}></div>
                    </Form.Group>
                    <Form.Group controlId="buyerGsmNumber">
                      <Form.Label>GSM Numarası</Form.Label>
                      <Form.Control
                        type="text"
                        value={buyer.gsmNumber}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (/^\d{0,10}$/.test(inputValue)) {
                            setBuyer({
                              ...buyer,
                              gsmNumber: inputValue.replace(/[^0-9]/g, ""),
                            });
                          }
                        }}
                        placeholder="5xxxxxxxxx"
                      />
                    </Form.Group>
                    <Form.Group controlId="buyerIdentityNumber">
                      <Form.Label>T.C. Kimlik Numarası</Form.Label>
                      <Form.Control
                        type="text"
                        value={buyer.identityNumber}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            /^\d{0,11}$/.test(inputValue) ||
                            inputValue === ""
                          ) {
                            setBuyer({
                              ...buyer,
                              identityNumber: inputValue,
                            });
                          }
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="buyerCity">
                      <Form.Label>Şehir</Form.Label>
                      <Form.Control
                        type="text"
                        value={buyer.city}
                        onChange={(e) =>
                          setBuyer({
                            ...buyer,
                            city: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    <Form.Group controlId="buyerCountry">
                      <Form.Label>Ülke</Form.Label>
                      <Form.Control
                        type="text"
                        value={buyer.country}
                        onChange={(e) =>
                          setBuyer({
                            ...buyer,
                            country: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    <Form.Group controlId="buyerRegistrationAddress">
                      <Form.Label>Adres</Form.Label>
                      <Form.Control
                        type="text"
                        as="textarea"
                        value={buyer.registrationAddress}
                        onChange={(e) =>
                          setBuyer({
                            ...buyer,
                            registrationAddress: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card style={styles.card}>
                <Card.Body>
                  <h2>Fatura Adresi</h2>
                  <Form>
                    <Form.Group controlId="billingContactName">
                      <Form.Label>İrtibat Kişisi</Form.Label>
                      <Form.Control
                        type="text"
                        value={billingAddress.contactName}
                        onChange={(e) => {
                          const contactValue = e.target.value;
                          setBillingAddress({
                            ...billingAddress,
                            contactName: contactValue,
                          });
                          setShippingAddress({
                            ...shippingAddress,
                            contactName: contactValue,
                          });
                        }}
                      />
                    </Form.Group>

                    <Form.Group controlId="billingCity">
                      <Form.Label>Şehir</Form.Label>
                      <Form.Control
                        type="text"
                        value={billingAddress.city}
                        onChange={(e) => {
                          const cityValue = e.target.value;
                          setBillingAddress({
                            ...billingAddress,
                            city: cityValue,
                          });
                          setShippingAddress({
                            ...shippingAddress,
                            city: cityValue,
                          });
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="billingCountry">
                      <Form.Label>Ülke</Form.Label>
                      <Form.Control
                        type="text"
                        value={billingAddress.country}
                        onChange={(e) => {
                          const countryValue = e.target.value;
                          setBillingAddress({
                            ...billingAddress,
                            country: countryValue,
                          });
                          setShippingAddress({
                            ...shippingAddress,
                            country: countryValue,
                          });
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="billingAddress">
                      <Form.Label>Adres</Form.Label>
                      <Form.Control
                        type="text"
                        as="textarea"
                        value={billingAddress.address}
                        onChange={(e) => {
                          const addressValue = e.target.value;
                          setBillingAddress({
                            ...billingAddress,
                            address: addressValue,
                          });
                          setShippingAddress({
                            ...shippingAddress,
                            address: addressValue,
                          });
                        }}
                      />
                    </Form.Group>
                  </Form>
                </Card.Body>
              </Card>
              <Card style={styles.card}>
                <Card.Body>
                  <h2>Kart Bilgileri</h2>
                  <Form>
                    <Form.Group controlId="cardHolderName">
                      <Form.Label>Kart Sahibinin Adı Soyadı</Form.Label>
                      <Form.Control
                        type="text"
                        value={paymentCard.cardHolderName}
                        onChange={(e) =>
                          setPaymentCard({
                            ...paymentCard,
                            cardHolderName: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    <Form.Group controlId="cardNumber">
                      <Form.Label>Kart Numarası</Form.Label>
                      <Form.Control
                        type="text"
                        value={paymentCard.cardNumber}
                        onChange={(e) =>
                          setPaymentCard({
                            ...paymentCard,
                            cardNumber: e.target.value,
                          })
                        }
                        maxLength={16}
                      />
                    </Form.Group>
                    <Row>
                      <Form.Group as={Col} controlId="expireMonth">
                        <Form.Label>Son Kullanma Tarihi</Form.Label>
                        <Row>
                          <Col>
                            <Form.Control
                              type="text"
                              value={paymentCard.expireMonth}
                              onChange={(e) =>
                                setPaymentCard({
                                  ...paymentCard,
                                  expireMonth: e.target.value,
                                })
                              }
                              placeholder="Ay"
                            />
                          </Col>
                          /
                          <Col>
                            <Form.Control
                              type="text"
                              value={paymentCard.expireYear}
                              onChange={(e) =>
                                setPaymentCard({
                                  ...paymentCard,
                                  expireYear: e.target.value,
                                })
                              }
                              placeholder="Yıl"
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group as={Col} controlId="cvc">
                        <Form.Label>CVV</Form.Label>
                        <Form.Control
                          type="text"
                          value={paymentCard.cvc}
                          onChange={(e) =>
                            setPaymentCard({
                              ...paymentCard,
                              cvc: e.target.value,
                            })
                          }
                          maxLength={3}
                        />
                      </Form.Group>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              {/* <Card style={styles.card}>
                <Card.Body>
                  <h2>Ödeme Bilgileri</h2>
                  <Form>
                    <Form.Group controlId="cardHolderName">
                      <Form.Label>Kart Sahibinin Adı Soyadı</Form.Label>
                      <Form.Control
                        type="text"
                        value={paymentCard.cardHolderName}
                        onChange={(e) =>
                          setPaymentCard({
                            ...paymentCard,
                            cardHolderName: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    <Form.Group controlId="cardNumber">
                      <Form.Label>Kart Numarası</Form.Label>
                      <Form.Control
                        type="text"
                        value={paymentCard.cardNumber}
                        onChange={(e) =>
                          setPaymentCard({
                            ...paymentCard,
                            cardNumber: e.target.value,
                          })
                        }
                        maxLength={16}
                      />
                    </Form.Group>
                    <Row>
                      <Form.Group as={Col} controlId="expireMonth">
                        <Form.Label>Son Kullanma Ayı</Form.Label>
                        <Form.Control
                          type="text"
                          value={paymentCard.expireMonth}
                          onChange={(e) =>
                            setPaymentCard({
                              ...paymentCard,
                              expireMonth: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="expireYear">
                        <Form.Label>Son Kullanma Yılı</Form.Label>
                        <Form.Control
                          type="text"
                          value={paymentCard.expireYear}
                          onChange={(e) =>
                            setPaymentCard({
                              ...paymentCard,
                              expireYear: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="cvc">
                        <Form.Label>CVV</Form.Label>
                        <Form.Control
                          type="text"
                          value={paymentCard.cvc}
                          onChange={(e) =>
                            setPaymentCard({
                              ...paymentCard,
                              cvc: e.target.value,
                            })
                          }
                          maxLength={3}
                        />
                      </Form.Group>
                    </Row>
                  </Form>
                </Card.Body>
              </Card> */}
            </Col>
          </Row>
        </Col>
        <Col xs={4}>
          <Card style={styles.card}>
            <Card.Body>
              <h2>Alınacak Hizmetler</h2>
              <h4>Modüller</h4>
              {modules.map((module) => (
                <Form.Check
                  key={module.id}
                  type="checkbox"
                  label={module.label + " - " + "₺" + module.price}
                  value={module.price}
                  onChange={handleCheckboxChange}
                />
              ))}
              <h4>Diller</h4>
              {languages.map((language) => (
                <Form.Check
                  key={language.id}
                  type="checkbox"
                  label={language.label + " - " + "₺" + language.price}
                  value={language.price}
                  onChange={handleCheckboxChange}
                />
              ))}
            </Card.Body>
          </Card>
          <Card style={styles.card}>
            <Card.Body>
              <h2>Ödeme Bilgileri</h2>
              <Card.Text>Ödeme Miktarı: {paymentCost}</Card.Text>
              <Form onSubmit={handlePaymentSubmit}>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={!isPaymentFormValid()}
                >
                  Ödemeyi Yap
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Pay;
