import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import LoginForm from './components/LoginForm';
import Dashboard from './components/Dashboard/Dashboard';
import AddQuestion from './components/AdminPanel/AddQuestion';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import PrivateRoute from './helper/PrivateRoute';
import NavigationBar from './components/NavigationBar';
import './App.css';
import Lessons from './components/AdminPanel/Lessons';
import Courses from './components/AdminPanel/Courses';
import Users from './components/AdminPanel/Users';
import Exams from './components/AdminPanel/Exams';
import Sections from './components/AdminPanel/Sections';
import AddSectionPart from './components/AdminPanel/AddSectionPart';
import Audio from './components/Books/Audio';
import Books from './components/Books/Books';
import SectionParts from './components/Books/SectionPart';
import Lesson from './components/Lessons/Lesson';
import Exam from './components/Exams/Exams'
import Questions from './components/Questions/Questions';
import AdminRoute from './helper/AdminRoute';
import UsersRoute from './helper/UsersRoute';
import Languages from './components/AdminPanel/Languages';
import PayPage from './components/Pay/PayPage';
import Pay from './components/Pay/Pay';
import Modules from './components/AdminPanel/Modules';
import ExamCates from './components/AdminPanel/ExamCategories';
import LessonCates from './components/AdminPanel/LessonCategories';
import ExamCategories from './components/Exams/ExamCategories';
import LessonCategories from './components/Lessons/LessonCategories';
import PayRoute from './helper/PayRoute';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import Contact from './components/Contact/Contact';
import TestRoute from './helper/TestRoute';
import PaymentPrices from './components/AdminPanel/PaymentPrices';
import CourseInfo from './components/AdminPanel/CourseInfo';

function App() {

  return (
    <AuthProvider>
      <Router>
        <div>
          <NavigationBar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/dashboard" element={<PrivateRoute> <Dashboard /> </PrivateRoute>} />
            <Route path="/addquestion/:examId" element={<AdminRoute> <AddQuestion /> </AdminRoute>} />
            <Route path="/lessons" element={<TestRoute> <Lessons /> </TestRoute>} />
            <Route path="/courses" element={<AdminRoute> <Courses /> </AdminRoute>} />
            <Route path="/users" element={<UsersRoute> <Users /> </UsersRoute>} />
            <Route path="/exams" element={<AdminRoute> <Exams /> </AdminRoute>} />
            <Route path="/sections" element={<TestRoute> <Sections /> </TestRoute>} />
            <Route path="/languages" element={<TestRoute> <Languages /> </TestRoute>} />
            <Route path="/modules" element={<TestRoute> <Modules /> </TestRoute>} />
            <Route path="/addsectionpart/:sectionId" element={<TestRoute> <AddSectionPart /> </TestRoute>} />
            <Route path="/audio/:sectionPartId" element={<PrivateRoute> <Audio /> </PrivateRoute>} />
            <Route path="/books" element={<PrivateRoute> <Books /> </PrivateRoute>} />
            <Route path="/bookparts/:sectionId" element={<PrivateRoute> <SectionParts /> </PrivateRoute>} />
            <Route path="/books" element={<PrivateRoute> <Books /> </PrivateRoute>} />
            <Route path="/lesson/:categoryId" element={<PrivateRoute> <Lesson /> </PrivateRoute>} />
            <Route path="/exam/:categoryId" element={<PrivateRoute> <Exam /> </PrivateRoute>} />
            <Route path="/questions/:examId" element={<PrivateRoute> <Questions /> </PrivateRoute>} />
            <Route path="/pay" element={<PayRoute><PayPage /></PayRoute>} />
            <Route path="/payment" element={<PayRoute><Pay /></PayRoute>} />
            <Route path="/examcategories" element={<AdminRoute> <ExamCates /> </AdminRoute>} />
            <Route path="/lessoncategories" element={<TestRoute> <LessonCates /> </TestRoute>} />
            <Route path="/examcates" element={<PrivateRoute> <ExamCategories /> </PrivateRoute>} />
            <Route path="/lessoncates" element={<PrivateRoute> <LessonCategories /> </PrivateRoute>} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/paymentprices" element={<AdminRoute> <PaymentPrices /> </AdminRoute>} />
            <Route path="/courseinfo" element={<UsersRoute> <CourseInfo /> </UsersRoute>} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

function Home() {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  if(location.pathname === '/privacypolicy') return
  if(location.pathname === '/contact') return

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return <LoginForm />;
}

export default App;
