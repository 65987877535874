import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  ListGroup,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import { FaBook, FaChalkboardTeacher, FaClipboardList } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  getSectionsByLanguage,
  getExamCatesByLanguage,
} from "../../services/apiService";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
import { useAuth } from "../../contexts/AuthContext";
import info from "../../theme/assets/yasal_bilgilendirme_metni.png";
import Footer from "../Footer/Footer";
import { USER_ROLES, decodeToken } from "../../helper/constants";

function Dashboard() {
  const { user } = useAuth();
  const { t } = useTranslation(["dashboard"]);
  const [exams, setExams] = useState([]);
  const [sections, setSections] = useState([]);
  const [hoveredCardIndex, setHoveredCardIndex] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [isRead, setIsRead] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = t("headers:main-page");
    const isRead = localStorage.getItem("isRead");
    setIsRead(isRead === "false" ? false : true);
  }, []);

  useEffect(() => {
    fetchLatestExams();
    fetchBooks();
  }, [i18next.language]);

  const fetchLatestExams = async () => {
    const exams = await getExamCatesByLanguage(i18next.language, user.moduleId);
    setExams(exams);
  };

  const fetchBooks = async () => {
    const books = await getSectionsByLanguage(i18next.language, user.moduleId);
    setSections(books);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleClose = () => {
    setIsRead(false);
    localStorage.setItem("isRead", !isChecked);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <div style={{ flex: 1 }}>
      <Container
        fluid
        style={{
          // backgroundImage: `url(${bgIcon})`,
          // backgroundPosition: 'center',
          // backgroundSize: '15%',
          // backgroundRepeat: 'no-repeat',
          flex: 1,
        }}
      >
        <Row className="mt-4">
          <Col md={6}>
            <Card className="dashboard-card snavlar">
              <Card.Body>
                <Card.Title>
                  <FaClipboardList className="dashboard-icon" />{" "}
                  {t("dashboard:exams")}
                </Card.Title>
                <Card.Text>{t("dashboard:exams-desc")}</Card.Text>
                <Link to="/examcates" className="btn btn-primary">
                  {t("dashboard:details")}
                </Link>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="dashboard-card kitaplar">
              <Card.Body>
                <Card.Title>
                  <FaBook className="dashboard-icon" /> {t("dashboard:books")}
                </Card.Title>
                <Card.Text>{t("dashboard:books-desc")}</Card.Text>
                <Link to="/books" className="btn btn-success">
                  {t("dashboard:details")}
                </Link>
              </Card.Body>
            </Card>
          </Col>
          {/* <Col md={4}>
          <Card className="dashboard-card dersler">
            <Card.Body>
              <Card.Title>
                <FaChalkboardTeacher className="dashboard-icon" /> {t('dashboard:lessons')}
              </Card.Title>
              <Card.Text>
                {t('dashboard:lessons-desc')}
              </Card.Text>
              <Link to="/lessoncates" className="btn btn-warning">
                {t('dashboard:details')}
              </Link>
            </Card.Body>
          </Card>
        </Col> */}
        </Row>
        <Row className="mt-4">
          <Col
            md={6}
            className="d-flex justify-content-center align-items-center"
          >
            <Card style={{ width: "100%" }} className="h-100">
              <Card.Title className="text-center mt-3">
                <FaChalkboardTeacher className="dashboard-icon" />{" "}
                {t("dashboard:exams")}
              </Card.Title>
              {exams.map((exam, index) => (
                <Link
                  style={{ textDecoration: "none" }}
                  key={exam.id}
                  to={`/exam/${exam.id}`}
                >
                  <ListGroup>
                    <ListGroup.Item
                      style={{
                        fontSize: 18,
                        backgroundColor: index % 2 === 1 ? "#f0f0f0" : "white",
                      }}
                      className="flex-grow-1"
                    >
                      {/* <Card.Title> */}
                      <div style={{ marginLeft: 15 }}>{exam.name}</div>
                      {/* </Card.Title> */}
                    </ListGroup.Item>
                  </ListGroup>
                </Link>
              ))}
            </Card>
          </Col>
          <Col
            md={6}
            className="d-flex justify-content-center align-items-center"
          >
            <Card className="h-100">
              <Card.Title className="text-center mt-3">
                <FaBook className="dashboard-icon" /> {t("dashboard:lessons")}
              </Card.Title>
              <Row className="mt-3 mx-2 justify-content-center">
                {sections.map((book, index) => (
                  <Col
                    key={index}
                    md={3}
                    className="mb-4"
                    onMouseEnter={() => setHoveredCardIndex(index)}
                    onMouseLeave={() => setHoveredCardIndex(null)}
                  >
                    <Card
                      className="mx-0"
                      style={{
                        transform:
                          hoveredCardIndex === index
                            ? "scale(1.05)"
                            : "scale(1)",
                        boxShadow:
                          hoveredCardIndex === index
                            ? "0 0 10px rgba(0, 0, 0, 0.5)"
                            : "0 5px 5px rgba(0, 0, 0, 0.5)",
                        backgroundColor: "white",
                        borderRadius: 5,
                        alignSelf: "center",
                        borderBottomColor: "#03045e",
                        borderBottomWidth: 5,
                      }}
                      onClick={() => navigate(`/bookparts/${book.id}`)}
                    >
                      <Card.Img
                        variant="top"
                        src={book.image}
                        alt={book.name}
                      />
                      <Card.Body
                        className="flex-grow-1 text-center"
                        style={{
                          fontFamily:
                            i18next.language === "ar" ||
                            i18next.language === "fa" ||
                            i18next.language === "ru"
                              ? ""
                              : "Anton",
                        }}
                      >
                        {book.name}
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal size="xl" show={isRead}>
          <Modal.Header>
            <Modal.Title>{t("dashboard:info-message")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img
              src={info}
              alt="info"
              style={{
                width: "90%",
                height: "90%",
                display: "block",
                margin: "auto",
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Form.Check
              type="checkbox"
              label="Okudum, anladım ve kabul ediyorum."
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <Button
              style={{ marginLeft: "auto" }}
              className="btn btn-primary"
              disabled={!isChecked}
              onClick={handleClose}
            >
              Onayla
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
      </div>
      <Footer />
    </div>
  );
}

export default Dashboard;
