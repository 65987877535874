import React, { useEffect, useState } from 'react';
import { Container, Card } from 'react-bootstrap';
import { getLessonCatesByLanguage, getLessonsByLanguage } from '../../services/apiService';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useAuth } from '../../contexts/AuthContext';

export default function LessonCategories() {
  const { user } = useAuth();
  const { t } = useTranslation(['dashboard']);
  const [lessons, setLessons] = useState([]);

  useEffect(() => {
    document.title = t('headers:lessons');
  }, [])

  useEffect(() => {
    fetchLessons()
  }, [i18next.language])

  const fetchLessons = async () => {
    const lessons = await getLessonCatesByLanguage(i18next.language, user.moduleId);
    setLessons(lessons)
  }

  return (
    <Container>
      {lessons.map((lesson) => (
        <Link key={lesson.id} to={`/lesson/${lesson.id}`}>
          <Card style={{ margin: '10px' }} className='text-center'>
            <Card.Body>
              <Card.Title>
                {lesson.name}
              </Card.Title>
            </Card.Body>
          </Card>
        </Link>
      ))}
    </Container>
  );
};
