import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa";
// import { FaXTwitter } from "react-icons/fa6";
// import { Link } from "react-router-dom";
import bg from "../../theme/assets/footerbg.png";
import surucu_yildizi from "../../theme/assets/surucu_yildizi_logo.png";
import yildiz_logo from "../../theme/assets/yildiz_logo.png";
import x_icon from "../../theme/assets/x_icon.png";
import whatsapp_icon from "../../theme/assets/whatsapp_icon.png";
import linkedin_icon from "../../theme/assets/linkedin_icon.png";
import facebook_icon from "../../theme/assets/facebook_icon.png";
import instagram_icon from "../../theme/assets/instagram_icon.png";
import { useAuth } from "../../contexts/AuthContext";

const Footer = () => {
  const { user } = useAuth();

  return (
    <footer
      className="bg-light py-0"
      style={{ width: "100%", bottom: 0 }}
    >      
      <Container fluid>
        <Row className="py-0">
          <Col md={4}>
            <img
              src={bg}
              alt="Footer Logo"
              style={{ height: '100%', width: "100%", objectFit: "cover" }}
            />
          </Col>
          <Col md={2} className="py-4 px-4">
            <div>
              {user?.course.address && <p>{user?.course.address}</p>}
              {user?.course.phone && <p>{user?.course.phone}</p>}
              {user?.course.email && <p>{user?.course.email}</p>}
            </div>
            <div style={{ flexDirection: 'row' }}>
              {user?.course.whatsapp && <img
                src={whatsapp_icon}
                alt="whatsapp Logo"
                style={{ height: 50, width: 50, objectFit: "cover", cursor: 'pointer' }}
                onClick={() => window.open(`https://wa.me/${user?.course.whatsapp}`, "_blank")}
              />}
              {user?.course.facebook && <img
                src={facebook_icon}
                alt="facebook Logo"
                style={{ height: 50, width: 50, objectFit: "cover", cursor: 'pointer' }}
                onClick={() => window.open(user?.course.facebook, "_blank")}
              />}
              {user?.course.instagram && <img
                src={instagram_icon}
                alt="instagram Logo"
                style={{ height: 50, width: 50, objectFit: "cover", cursor: 'pointer' }}
                onClick={() => window.open(user?.course.instagram, "_blank")}
              />}
              {user?.course.twitter && <img
                src={x_icon}
                alt="x Logo"
                style={{ height: 50, width: 50, objectFit: "cover", cursor: 'pointer' }}
                onClick={() => window.open(user?.course.twitter, "_blank")}
              />}
              {user?.course.linkedin && <img
                src={linkedin_icon}
                alt="linkedin Logo"
                style={{ height: 50, width: 50, objectFit: "cover", cursor: 'pointer' }}
                onClick={() => window.open(user?.course.linkedin, "_blank")}
              />}
            </div>
          </Col>
          <Col md={4} className="py-4">
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginBottom: 15 }}>
                {/* <img
                  src={surucu_yildizi}
                  alt="surucu_yildizi Logo"
                  style={{ height: 200, width: 200 }}
                />
                <img
                  src={yildiz_logo}
                  alt="yildiz_logo Logo"
                  style={{ height: 200, width: 200 }}
                /> */}
                <img
                  src={user?.course.icon}
                  alt="Kurs Logo"
                  style={{ height: 200, width: 200 }}
                />
              </div>
              
              <p className="text-center mb-0" style={{ fontSize: 12 }}>
                Telif Hakkı © {new Date().getFullYear()} Yıldız Basımevi ve Matbaacılık. Tüm
                Hakları Saklıdır.
              </p>
            </div>
          </Col>
          <Col md={2} className="py-4 px-4">
            <h5>BASILI YAYIN VE HİZMETLERİMİZ</h5>
            <p style={{ margin: 0 }}>Sürücü Eğitim Kitapları</p>
            <p>Sürücü Eğitim Çeviri Kitapları</p>
            <p style={{ margin: 0 }}>SRC Eğitim Kitapları</p>
            <p>SRC Deneme Testleri</p>
            <p>ADR Eğitim Kitapları</p>
          </Col>
          {/* <Col md={2} className="py-0">
            <img
              src={bg}
              alt="Footer Logo"
              style={{ height: '100%', width: "100%", objectFit: "cover" }}
            />
          </Col> */}
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
