import React from "react";

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <h1>Sürücü Yıldızı Gizlilik Bildirimi</h1>
      <p>Sürüm, 24 Mayıs 2024</p>

      <p>
        Tüm kullanıcılarımızın gizliliği bizim için çok önemlidir. Bir Uygulama
        Kullanıcısı olarak Hizmetimizi kullandığınızda, (Uygulama Yayıncısı
        olarak) kişisel verilerinizi işleriz. Bu Gizlilik Bildirimi, kişisel
        verilerinizi nasıl koruduğumuzu ve işleme koyduğumuzu açıklar.
        Dikkatlice okumanızı öneririz.
      </p>

      <h2>1. Bu Gizlilik Bildiriminde ne belirtilmiştir?</h2>
      <p>
        Bu Gizlilik Bildirimindeki bölümler size aşağıdakiler hakkında bilgi
        verir:
      </p>
      <ul>
        <li>İşlenen kişisel verilerin türleri,</li>
        <li>Kişisel verilerin işlenme amaçları,</li>
        <li>Kişisel verilerin işlendiği yerler,</li>
        <li>Kişisel verileri korumak için uygulanan güvenlik önlemleri,</li>
        <li>Üçüncü şahıslarla ilgili sorumluluk sınırları,</li>
        <li>Kişisel verilerinizi görüntüleme, değiştirme ve silme,</li>
        <li>Bu Gizlilik Bildiriminde yapılan değişiklikler,</li>
        <li>Sorunuz veya açıklamalarınız varsa ne yapacağınız.</li>
      </ul>

      <h2>2. İşlenen Kişisel Verilerin Türleri</h2>

      <h3>A. Hizmetimiz tarafından kullanılan Kişisel Veriler</h3>
      <p>
        Hizmetimizi geliştirmek için anonim verileri kullanırız. Bu bilgiler,
        hizmet aracılığıyla kamuya açıklanmayacaktır.
      </p>

      <h3>B. Otomatik olarak oluşturulan bilgiler</h3>
      <p>
        Çoğu diğer web siteleri ve çevrimiçi hizmetler gibi, uygulamayı nasıl
        kullandığınızla ilgili olarak otomatik olarak üretilen bilgileri toplar
        ve işleriz. Toplanan bilgiler, IP adresinizi ve/veya benzersiz cihaz
        kimliğinizi içerir.
      </p>
      <h2>3. Kişisel Verilerin İşlenme Amaçları</h2>

      <h3>A. Amaçlar</h3>
      <p>Taraflar, kişisel verileri aşağıdaki amaçlarla işlemektedir:</p>
      <ul>
        <li>Hizmeti kullanabilmenizi sağlamak için,</li>
        <li>Hizmetimizle ilgili bilgileri güncel tutmak için,</li>
        <li>Hizmetin iyileştirilmesi ve/veya özelleştirilmesi için,</li>
        <li>Sizi tanımlamak ve/veya hizmeti özelleştirmek için,</li>
        <li>Sizi tanımak ve dolandırıcılığı önlemek için,</li>
        <li>Destek sağlamak için,</li>
        <li>
          Kişisel verilerinizi, bizden bunu talep ettiğiniz takdirde veya yasal
          olarak yükümlü olduğumuz durumlarda, üçüncü şahıslara iletmek.
        </li>
      </ul>

      <h3>B. Kişisel Verilerin Üçüncü Şahıslara İletilmesi</h3>
      <p>
        Sizden önceden izin alınmaksızın kişisel verilerinizi üçüncü şahıslara
        satmamaktayız, satmıyor veya kiralamıyoruz. Bununla birlikte, ücretli
        hizmetlerle ilgili ödeme sağlayıcıları aracılığıyla ödeme yapmak gibi
        kişisel verilerinizi size sağlama veya talimatları yerine getirmeniz
        açısından şart olduğu durumlarda, kişisel verilerinizi üçüncü taraflara
        sağlayabiliriz.
      </p>
      <p>
        Üçüncü bir tarafa geçtiğimiz veya bir üçüncü tarafla birleştiğimiz veya
        bir yeniden yapılandırmaya tabi olduğumuz durumda, kişisel verileriniz
        de üçüncü kişilere açıklanabilir ve/veya bu üçüncü kişiye
        devredilebilir. Bu üçüncü taraf, kişisel verileri ve bize sağladığınız
        diğer bilgileri kullanmaya devam etme hakkına sahip olacak.
      </p>
      <p>
        Kişisel verilerinizi, iyi niyetle, bir mahkeme kararı, halen devam eden
        adli işlem, cezai veya sivil mahkeme celbi veya kolluk kuvveti makamları
        tarafından verilen diğer yasal işlem veya taleplere uymanız gerektiğine
        inandığımız yerlerde ifşa edebiliriz veya yasal hakları veya yasal
        iddialara karşı savunmak.
      </p>

      <h2>4. Kişisel Verilerin İşlendiği Yerler</h2>
      <p>
        Hizmet kapsamında işlenen verilerin tutulduğu veri merkezleri Türkiye,
        İrlanda, Hollanda ve Amerika Birleşik Devletleri bölgelerinde yer alan
        barındırma hizmetleri kullanılarak sağlanmaktadır. Bununla birlikte,
        işlenen kişisel veriler, bu ülkeler ve bölgeler dışında bir ülkede
        bulunan muhafaza sunucularına aktarılabilir ve depolanabilir. Kişisel
        verilerinizin güvenli bir şekilde ve bu gizlilik politikasına uygun
        şekilde kullanılmasını sağlamak için makul ölçüde gerekli tüm adımları
        atacağız.
      </p>

      <h2>
        5. Kişisel Verileri Korumak İçin Hangi Güvenlik Önlemleri
        Uygulanmaktadır?
      </h2>
      <p>
        Verilerinizin güvenliği ve diğer kullanıcıların güvenliği bizim için çok
        önemlidir. Kişisel verilerinizi kayıp ya da herhangi bir yasadışı işleme
        karşı korumak için teknik ve organizasyonel önlemler aldık. Aşağıdaki
        tedbirleri uyguladık: sunucularımızı güvenlik duvarları, SSL
        bağlantıları ve hassas verilerin şifrelenmesi yoluyla koruma. Bu liste
        ayrıntılı değildir.
      </p>

      <h2>6. Üçüncü Şahıslarla İlgili Sorumluluk Sınırları</h2>
      <p>
        Hizmetimiz üçüncü taraflar tarafından sunulan hizmetler ve ürünler
        ve/veya ortakların, reklamverenlerin ve diğer üçüncü tarafların web
        sitelerine veya hizmetlerine köprüler içerebilir.
      </p>
      <p>
        Tarafların, bu üçüncü tarafların içeriği, web siteleri veya hizmetleri
        üzerinde herhangi bir kontrolü veya etkisi yoktur. Üçüncü kişi web
        sitelerinin ve hizmetlerinin kullanımı için farklı gizlilik politikaları
        uygulanabilir. Bu Gizlilik Beyanı, yalnızca taraflar tarafından hizmeti
        kendi amaçları doğrultusunda kullanmanız yoluyla elde edilen kişisel
        verilerle ilgilidir. Taraflar, üçüncü taraf web sitelerinin ve
        hizmetlerin içeriği, uygulamaları veya işletilmesi ile ilgili herhangi
        bir sorumluluk kabul etmez.
      </p>

      <h2>7. Kişisel Verileri Görüntüleme ve Silme</h2>
      <p>
        Uygulamayı kullanarak toplanan kişisel bilgilere e-posta yoluyla bizimle
        iletişime geçmek veya silmek için bir istek gönderebilirsiniz.
        Kimliğinizi doğrulamak için ek bilgi sağlamanız istenebilir.
      </p>

      <h2>8. Çocuk Gizliliği</h2>
      <p>
        Çocuklarla ilgili kişisel bilgiler bilerek veya kasıtlı olarak
        toplanmamaktadır.
      </p>

      <h2>9. Güvenlik</h2>
      <p>
        Kişisel bilgilerinizin güvenliğini sağlamak, hasar riskini en aza
        indirmek, bilgi kaybı ve yetkisiz erişimden veya bilgiyi kullanmak için
        önlemler uygulanmaktadır. Bununla birlikte, bu tedbirler mutlak bilgi
        güvenliği sağlayamamaktadır. Dolayısıyla, kişisel bilgilerinizin
        güvenliğini sağlamak için çaba sarf edilir, ancak bu garanti edilmez ve
        uygulamanın ve ilgili veritabanlarının herhangi bir haksız davranış,
        arıza, izinsiz engeller veya erişim veya diğer kötüye kullanım ve kötüye
        kullanımdan muaf olacağını makul bir şekilde bekleyemezsiniz.
      </p>

      <h2>10. Bu Gizlilik Bildiriminde Yapılan Değişiklikler</h2>
      <p>
        Bu beyan, herhangi bir zamanda güncellenebilir. Taraflar, Gizlilik
        Bildirimi'nin güncellenmiş bir sürümünü hizmet aracılığıyla
        yayınlayacaktır. Taraflar, bu Gizlilik Bildirimi'nde yapacağı
        değişikliklerden haberdar olmak ve tarafların kişisel verilerinizi nasıl
        koruma altına alacakları konusunda bilgi sahibi olmak için bu sayfayı
        zaman zaman kontrol etmenizi önerir. Bu Gizlilik Bildirimini periyodik
        olarak gözden geçirmek ve güncellemeler hakkında bilgi sahibi olmak
        sizin sorumluluğunuzdadır ve kabul ve taahhüt eder.
      </p>
      <p>
        Bu bildirimde yapılan değişikliklerin herhangi birine bağlı kalmayı
        kabul etmektesiniz. Değişiklik yürürlüğe girdikten sonra uygulamayı
        kullanmaya devam etmeniz, değiştirilen bildirimi kabul ettiğinizi
        gösterecektir. Değiştirilen bildirimi kabul etmiyorsanız, uygulamayı
        kaldırmanız ve onu daha fazla kullanmaktan kaçınmanız gerekir.
      </p>

      <h2>
        11. Herhangi Bir Sorunuz ya da Açıklamalarınız Varsa Ne Yapacaksınız?
      </h2>
      <p>
        Bu Gizlilik Bildirimi ile ilgili herhangi bir sorunuz veya
        açıklamalarınız varsa, lütfen bir e-posta göndererek bizimle iletişime
        geçin.
      </p>

      <p>
        Bu Gizlilik Bildirimi son olarak 24 Mayıs 2024 tarihinde güncellendi.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
