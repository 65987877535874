// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-container {
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  cursor: zoom-in;
}

.image-container.zoomed img {
  transform: scale(2.25); /* Örnek bir büyütme oranı */
  transform-origin: center; /* Büyütme merkezi */
  transition: all 0.3s ease-in-out;
  cursor: zoom-out;
}

.zoomable-image.zoomed {
  background-size: 500% 500%; /* Fare pozisyonuna göre fotoğrafın büyüklüğü */
  cursor: zoom-in; /* Fareyi zoom-in şeklinde göster */
}`, "",{"version":3,"sources":["webpack://./src/components/Books/Audio.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,gCAAgC;EAChC,eAAe;AACjB;;AAEA;EACE,sBAAsB,EAAE,4BAA4B;EACpD,wBAAwB,EAAE,oBAAoB;EAC9C,gCAAgC;EAChC,gBAAgB;AAClB;;AAEA;EACE,0BAA0B,EAAE,+CAA+C;EAC3E,eAAe,EAAE,mCAAmC;AACtD","sourcesContent":[".image-container {\n  position: relative;\n  overflow: hidden;\n  transition: all 0.3s ease-in-out;\n  cursor: zoom-in;\n}\n\n.image-container.zoomed img {\n  transform: scale(2.25); /* Örnek bir büyütme oranı */\n  transform-origin: center; /* Büyütme merkezi */\n  transition: all 0.3s ease-in-out;\n  cursor: zoom-out;\n}\n\n.zoomable-image.zoomed {\n  background-size: 500% 500%; /* Fare pozisyonuna göre fotoğrafın büyüklüğü */\n  cursor: zoom-in; /* Fareyi zoom-in şeklinde göster */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
