import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useParams, useLocation } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import Card from "react-bootstrap/Card";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addQuestion,
  deleteQuestion,
  editQuestion,
  getPanelQuestionsbyExam,
} from "../../services/apiService";
import { Modal } from 'react-bootstrap';

function AddQuestion() {
  const { examId } = useParams();

  const location = useLocation();
  const { examName } = location.state;

  const [form, setForm] = useState({
    examId: examId,
    questionText: "",
    optionA: "",
    optionB: "",
    optionC: "",
    optionD: "",
    correctChoice: "A",
    image: "",
  });
  const [questions, setQuestions] = useState([]);
  const [editID, setEditID] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [editForm, setEditForm] = useState({
    examId: examId,
  });

  const fetchQuestions = async () => {
    const response = await getPanelQuestionsbyExam(examId);
    setQuestions(response);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await addQuestion(form);
    if (response) {
      fetchQuestions();
      toast.success("Soru eklendi");
      setForm({
        examId: examId,
        questionText: "",
        optionA: "",
        optionB: "",
        optionC: "",
        optionD: "",
        correctChoice: "A",
        image: "",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleDelete = async (questionId) => {
    const response = await deleteQuestion(questionId);
    if (response) fetchQuestions();
  };

  const handleImageUpload = (e, field) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setForm((prevForm) => ({
          ...prevForm,
          [field]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEditImageUpload = (e, field) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setEditForm((prevForm) => ({
          ...prevForm,
          [field]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEdit = (item) => {
    setEditID(item.id)
    setEditForm(item)
    setModalVisible(true)
  }

  const handleEditQuestion = async () => {
    const response = await editQuestion(editForm, editID)
    console.log(response)
    setModalVisible(false)
  }

  useEffect(() => {
    fetchQuestions();
  }, [examId]);

  return (
    <Container>
      <h2 className="my-4">Soru Ekle - {examName}</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Soru</Form.Label>
          <Form.Control
            type="text"
            name="questionText"
            as="textarea"
            value={form.questionText}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>A şıkkı</Form.Label>
          {!(
            form.optionA?.indexOf("data:image") === 0 ||
            form.optionA?.indexOf("data:video") === 0
          ) && (
            <Form.Control
              type="text"
              name="optionA"
              value={form.optionA}
              onChange={handleChange}
              required
            />
          )}
          <Form.Control
            type="file"
            accept="image/*,video/*"
            className="mt-2"
            onChange={(e) => handleImageUpload(e, "optionA")}
          />
          {form.optionA?.indexOf("data:image") === 0 && (
            <img
              src={form.optionA}
              alt="Soru Fotoğrafı"
              style={{ width: 200, marginTop: 10 }}
            />
          )}
          {form.optionA?.indexOf("data:video") === 0 && (
            <video controls width="320" height="180">
              <source src={form.optionA} type="video/mp4" />
              Tarayıcınız video etiketini desteklemiyor.
            </video>
          )}
          {(form.optionA?.indexOf("data:image") === 0 ||
            form.optionA?.indexOf("data:video") === 0) && (
            <Button
              variant="danger"
              style={{ marginTop: 10 }}
              onClick={() =>
                setForm((prevForm) => ({ ...prevForm, optionA: "" }))
              }
            >
              Fotoğraf/Video Kaldır
            </Button>
          )}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>B şıkkı</Form.Label>
          {!(form.optionB?.indexOf("data:image") === 0 ||
            form.optionB?.indexOf("data:video") === 0) && <Form.Control
            type="text"
            name="optionB"
            value={form.optionB}
            onChange={handleChange}
            required
          />}
          <Form.Control
            type="file"
            accept="image/*"
            className="mt-2"
            onChange={(e) => handleImageUpload(e, "optionB")}
          />
          {form.optionB?.indexOf("data:image") === 0 && (
            <img
              src={form.optionB}
              alt="Soru Fotoğrafı"
              style={{ width: 200, marginTop: 10 }}
            />
          )}
          {form.optionB?.indexOf("data:video") === 0 && (
            <video controls width="320" height="180">
              <source src={form.optionB} type="video/mp4" />
              Tarayıcınız video etiketini desteklemiyor.
            </video>
          )}
          {(form.optionB?.indexOf("data:image") === 0 ||
            form.optionB?.indexOf("data:video") === 0) && (
            <Button
              variant="danger"
              style={{ marginTop: 10 }}
              onClick={() =>
                setForm((prevForm) => ({ ...prevForm, optionB: "" }))
              }
            >
              Fotoğraf/Video Kaldır
            </Button>
          )}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>C şıkkı</Form.Label>
          {!(form.optionC?.indexOf("data:image") === 0 ||
            form.optionC?.indexOf("data:video") === 0) && <Form.Control
            type="text"
            name="optionC"
            value={form.optionC}
            onChange={handleChange}
            required
          />}
          <Form.Control
            type="file"
            accept="image/*,video/*"
            className="mt-2"
            onChange={(e) => handleImageUpload(e, "optionC")}
          />
          {form.optionC?.indexOf("data:image") === 0 && (
            <img
              src={form.optionC}
              alt="Soru Fotoğrafı"
              style={{ width: 200, marginTop: 10 }}
            />
          )}
          {form.optionC?.indexOf("data:video") === 0 && (
            <video controls width="320" height="180">
              <source src={form.optionC} type="video/mp4" />
              Tarayıcınız video etiketini desteklemiyor.
            </video>
          )}
          {(form.optionC?.indexOf("data:image") === 0 ||
            form.optionC?.indexOf("data:video") === 0) && (
            <Button
              variant="danger"
              style={{ marginTop: 10 }}
              onClick={() =>
                setForm((prevForm) => ({ ...prevForm, optionC: "" }))
              }
            >
              Fotoğraf/Video Kaldır
            </Button>
          )}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>D şıkkı</Form.Label>
          {!(form.optionD.indexOf("data:image") === 0 ||
            form.optionD.indexOf("data:video") === 0) && <Form.Control
            type="text"
            name="optionD"
            value={form.optionD}
            onChange={handleChange}
            required
          />}
          <Form.Control
            type="file"
            accept="image/*,video/*"
            className="mt-2"
            onChange={(e) => handleImageUpload(e, "optionD")}
          />
          {form.optionD.indexOf("data:image") === 0 && (
            <img
              src={form.optionD}
              alt="Soru Fotoğrafı"
              style={{ width: 200, marginTop: 10 }}
            />
          )}
          {form.optionD.indexOf("data:video") === 0 && (
            <video controls width="320" height="180">
              <source src={form.optionD} type="video/mp4" />
              Tarayıcınız video etiketini desteklemiyor.
            </video>
          )}
          {(form.optionD.indexOf("data:image") === 0 ||
            form.optionD.indexOf("data:video") === 0) && (
            <Button
              variant="danger"
              style={{ marginTop: 10 }}
              onClick={() =>
                setForm((prevForm) => ({ ...prevForm, optionD: "" }))
              }
            >
              Fotoğraf/Video Kaldır
            </Button>
          )}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Doğru şık</Form.Label>
          <Form.Select
            name="correctChoice"
            value={form.correctChoice}
            onChange={handleChange}
            required
          >
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
            <option value="D">D</option>
          </Form.Select>
        </Form.Group>
        <Form.Group controlId="image">
          <Form.Label>Fotoğraf/Video Ekle</Form.Label>
          <Form.Control
            type="file"
            accept="image/*,video/*"
            onChange={(e) => handleImageUpload(e, 'image')}
          />
          {form.image &&
            typeof form.image === "string" &&
            form.image.indexOf("data:image") === 0 && (
              <img
                src={form.image}
                alt="Soru Fotoğrafı"
                style={{ width: 200, marginTop: 10 }}
              />
            )}
          {form.image &&
            typeof form.image === "string" &&
            form.image.indexOf("data:video") === 0 && (
              <video controls width="320" height="180">
                <source src={form.image} type="video/mp4" />
                Tarayıcınız video etiketini desteklemiyor.
              </video>
            )}
          {(form.image.indexOf("data:image") === 0 ||
            form.image.indexOf("data:video") === 0) && (
            <Button
              variant="danger"
              style={{ marginTop: 10 }}
              onClick={() =>
                setForm((prevForm) => ({ ...prevForm, image: "" }))
              }
            >
              Fotoğraf/Video Kaldır
            </Button>
          )}
        </Form.Group>
        <Button variant="primary" type="submit" style={{ marginTop: 20 }}>
          Soru Ekle
        </Button>
      </Form>
      <ListGroup style={{ marginTop: 50 }}>
        {questions.map((question, index) => (
          <Card key={question._id} className="mb-3">
            <Card.Header>
              {index + 1 + ") " + question.questionText}
            </Card.Header>
            {/* {question.image &&
              typeof question.image === "string" &&
              question.image.indexOf("data:image") === 0 && (
                <Card.Img
                  variant="top"
                  src={question.image}
                  alt="Soru Fotoğrafı"
                />
              )}
            {question.image &&
              typeof question.image === "string" &&
              question.image.indexOf("data:video") === 0 && (
                <video controls width="640" height="360">
                  <source src={question.image} type="video/mp4" />
                  Tarayıcınız video etiketini desteklemiyor.
                </video>
              )} */}
            <ListGroup variant="flush">
              {/* <ListGroup.Item>A- {question.optionA}</ListGroup.Item>
              <ListGroup.Item>B- {question.optionB}</ListGroup.Item>
              <ListGroup.Item>C- {question.optionC}</ListGroup.Item>
              <ListGroup.Item>D- {question.optionD}</ListGroup.Item> */}
              <ListGroup.Item>
                Doğru şık: {question.correctChoice}
              </ListGroup.Item>

              <ListGroup.Item>
                <Button style={{ marginRight: 20 }} variant="info" onClick={() => handleEdit(question)}>Düzenle</Button>
                <Button
                  variant="danger"
                  onClick={() => handleDelete(question.id)}
                >
                  Sil
                </Button>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        ))}
      </ListGroup>
      <Modal show={modalVisible} onHide={() => setModalVisible(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Soru Düzenle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="icon">
              <Form.Label>Soru</Form.Label>
              <Form.Control
                type="text"
                name="questionText"
                value={editForm.questionText}
                onChange={handleEditChange}
                required
              />            
            </Form.Group>
            <Form.Group controlId="icon">
              <Form.Label>A Şıkkı</Form.Label>
              {!(
                editForm.optionA?.indexOf("data:image") === 0 ||
                editForm.optionA?.indexOf("data:video") === 0
              ) && (
                <Form.Control
                  type="text"
                  name="optionA"
                  value={editForm.optionA || ""}
                  onChange={handleEditChange}
                  required
                />
              )}
              <Form.Control
                type="file"
                accept="image/*"
                className="mt-2"
                onChange={(e) => handleEditImageUpload(e, "optionA")}
              />
              {editForm.optionA?.indexOf("data:image") === 0 && (
                <img
                  src={editForm.optionA || ""}
                  alt="Soru Fotoğrafı"
                  style={{ width: 200, marginTop: 10 }}
                />
              )}         
            </Form.Group>
            <Form.Group controlId="icon">
              <Form.Label>B Şıkkı</Form.Label>
              {!(
                editForm.optionB?.indexOf("data:image") === 0 ||
                editForm.optionB?.indexOf("data:video") === 0
              ) && (
                <Form.Control
                  type="text"
                  name="optionB"
                  value={editForm.optionB || ""}
                  onChange={handleEditChange}
                  required
                />
              )}
              <Form.Control
                type="file"
                accept="image/*"
                className="mt-2"
                onChange={(e) => handleEditImageUpload(e, "optionB")}
              />
              {editForm.optionB?.indexOf("data:image") === 0 && (
                <img
                  src={editForm.optionB || ""}
                  alt="Soru Fotoğrafı"
                  style={{ width: 200, marginTop: 10 }}
                />
              )}         
            </Form.Group>
            <Form.Group controlId="icon">
              <Form.Label>C Şıkkı</Form.Label>
              {!(
                editForm.optionC?.indexOf("data:image") === 0 ||
                editForm.optionC?.indexOf("data:video") === 0
              ) && (
                <Form.Control
                  type="text"
                  name="optionC"
                  value={editForm.optionC || ""}
                  onChange={handleEditChange}
                  required
                />
              )}
              <Form.Control
                type="file"
                accept="image/*"
                className="mt-2"
                onChange={(e) => handleEditImageUpload(e, "optionC")}
              />
              {editForm.optionC?.indexOf("data:image") === 0 && (
                <img
                  src={editForm.optionC || ""}
                  alt="Soru Fotoğrafı"
                  style={{ width: 200, marginTop: 10 }}
                />
              )}         
            </Form.Group>
            <Form.Group controlId="icon">
              <Form.Label>D Şıkkı</Form.Label>
              {!(
                editForm.optionD?.indexOf("data:image") === 0 ||
                editForm.optionD?.indexOf("data:video") === 0
              ) && (
                <Form.Control
                  type="text"
                  name="optionD"
                  value={editForm.optionD || ""}
                  onChange={handleEditChange}
                  required
                />
              )}
              <Form.Control
                type="file"
                accept="image/*"
                className="mt-2"
                onChange={(e) => handleEditImageUpload(e, "optionD")}
              />
              {editForm.optionD?.indexOf("data:image") === 0 && (
                <img
                  src={editForm.optionD || ""}
                  alt="Soru Fotoğrafı"
                  style={{ width: 200, marginTop: 10 }}
                />
              )}         
            </Form.Group>
            <Form.Group className="mb-3" controlId="image">
              <Form.Label>Soru Fotoğrafı/Videosu</Form.Label>
              <Form.Control type="file" accept="image/*,video/*" onChange={(e) => handleEditImageUpload(e, "image")} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setModalVisible(false)}
          >
            Kapat
          </Button>
          <Button variant="primary" onClick={handleEditQuestion}>
            Düzenle
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={true}
      />
    </Container>
  );
}

export default AddQuestion;
