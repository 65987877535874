import React, { useEffect, useState } from "react";
import { addCourse, deleteCourse, editCourse, getCourses, getLanguages, getModules, payCourse } from "../../services/apiService";
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  Modal,
} from "react-bootstrap";
import Sidebar from "../Sidebar/Sidebar";
import moment from 'moment';

export default function Courses() {
  const [modalVisible, setModalVisible] = useState(false)
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [courses, setCourses] = useState([])
  const [form, setForm] = useState({
    name: '',
    logo: '',
    languages: [],
    modules: []
  })
  const [editForm, setEditForm] = useState({
    name: '',
    logo: '',
    languages: [],
    modules: [],
  })
  const [editId, setEditID] = useState(null)
  const [languages, setLanguages] = useState([]);
  const [modules, setModules] = useState([]);

  useEffect(() => {
    fetchCourses()
    fetchLanguages()
    fetchModules()
  }, [])

  const fetchCourses = async () => {
    const course = await getCourses();
    setCourses(course)
  }

  const fetchLanguages = async () => {
    const languages = await getLanguages();
    setLanguages(languages)
  }

  const fetchModules = async () => {
    const modules = await getModules();
    setModules(modules)
  }

  const handleAddCourse = async () => {
    const response = await addCourse(form)
    if(response) fetchCourses()
    setModalVisible(false)
  }

  const handleEditCourse = async () => {
    const response = await editCourse(editForm, editId)
    if(response) fetchCourses()
    setEditModalVisible(false)
  }

  const handleDelete = async (id) => {
    const response = await deleteCourse(id)
    if(response) fetchCourses()
  }

  const handleEdit = (item) => {
    setEditForm({
      name: item.name,
      logo: item.icon,
      languages: item.languages,
      modules: item.modules,
      activeUserLimit: item.activeUserLimit,
    })
    setEditID(item.id)
    setEditModalVisible(true)
  }

  const handlePayment = async (id) => {
    const response = await payCourse(id)
    if(response) fetchCourses()
  }

  const handleImageUpload = (e, isEdit) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if(isEdit){
          setEditForm((prevForm) => ({
            ...prevForm,
            icon: reader.result
          }));
        }else{
          setForm((prevForm) => ({
            ...prevForm,
            icon: reader.result
          }));
        }
        
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCheckboxChange = (e, key, isEdit = false) => {
    const id = parseInt(e.target.value);

    if(isEdit){
      setEditForm((prevState) => {
        const items = prevState[key] || [];
        const isItemSelected = items.includes(id);

        return {
          ...prevState,
          [key]: isItemSelected
            ? items.filter((itemId) => itemId !== id)
            : [...items, id],
        };
      });
    } else{
      setForm((prevState) => {
        const items = prevState[key] || [];
        const isItemSelected = items.includes(id);
        return {
          ...prevState,
          [key]: isItemSelected
            ? items.filter((itemId) => itemId !== id)
            : [...items, id],
        };
      });
    }
  };


  return (
    <div style={{ flex: 1, height: "100vh", width: "100%" }}>
      <Row style={{ flex: 1, height: "100vh" }}>
        <Col sm={2} style={{ height: "100vh" }}>
          <Sidebar />
        </Col>
        <Col>
          <Container>
            <Container className="mt-3">
              <Row>
                <Col>
                  <div>
                    <Button
                      variant="primary"
                      onClick={() => setModalVisible(true)}
                    >
                      Kurs Ekle
                    </Button>
                    <Table className="mt-3" striped bordered hover>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>İsim</th>
                          <th>Logo</th>
                          <th>Ödeme</th>
                          <th>Oluşturulma Tarihi</th>
                          <th>Ödeme Tarihi</th>
                          <th>Diller</th>
                          <th>Modüller</th>
                          <th>Aktif Kullanıcı Limiti</th>
                          <th>Aktif Kullanıcı Sayısı</th>
                          <th>Toplam Kullanıcı Sayısı</th>
                          <th>İşlemler</th>
                        </tr>
                      </thead>
                      <tbody>
                        {courses.map((item) => (
                          <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.name}</td>
                            <td>
                              <img 
                                src={item.icon} 
                                className="d-inline-block align-top"
                                alt="Car Logo"
                                height='30'
                              />
                            </td>
                            <td>{item.isPayed ? "Yapıldı" : "Yapılmadı"}</td>
                            <td>{moment(item.createdAt).format('DD.MM.YYYY HH:mm')}</td>
                            <td>{item.payDate ? moment(item.payDate).format('DD.MM.YYYY HH:mm') : "-"}</td>
                            <td>{item.languages && item.languages.map(language => { 
                                const lang = languages.find(lang => lang.id === language)
                                return lang.label
                              }).join(', ')}
                            </td>
                            <td>{item.modules && item.modules.map(module => {
                                const mod = modules.find(mod => mod.id === module)
                                return mod.label
                              }).join(', ')}
                            </td>
                            <td>{item.activeUserLimit}</td>
                            <td>{item.activeUserCount}</td>
                            <td>{item.userCount}</td>
                            <td>
                              <Button variant="info" size="sm" onClick={() => handleEdit(item)}>
                                Düzenle
                              </Button>{" "}
                              <Button variant="primary" size="sm" onClick={() => handlePayment(item.id)}>
                                Ödeme Yap
                              </Button>{" "}
                              <Button variant="danger" size="sm" onClick={() => handleDelete(item.id)}>
                                Sil
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </Container>

            <Modal show={modalVisible} onHide={() => setModalVisible(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Kurs Ekle</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group controlId="formCourseName">
                    <Form.Label>Kurs Adı</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Kurs Adı"
                      onChange={(e) => setForm({
                        ...form,
                        name: e.target.value
                      })}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="icon">
                    <Form.Label>Logo Ekle</Form.Label>
                    <Form.Control type="file" accept="image/*" onChange={handleImageUpload} />
                  </Form.Group>
                  <Form.Group controlId="languages">
                    <Form.Label>Diller:</Form.Label>
                    {languages.map((lang) => (
                      <Form.Check
                        key={lang.id}
                        type="checkbox"
                        label={lang.label}
                        value={lang.id}
                        checked={form.languages && form.languages.includes(lang.id)}
                        onChange={(e) => handleCheckboxChange(e, "languages")}
                      />
                    ))}
                  </Form.Group>
                  <Form.Group controlId="modules">
                    <Form.Label>Modüller:</Form.Label>
                    {modules.map((module) => (
                      <Form.Check
                        key={module.id}
                        type="checkbox"
                        label={module.label}
                        value={module.id}
                        checked={form.modules && form.modules.includes(module.id)}
                        onChange={(e) => handleCheckboxChange(e, "modules")}
                      />
                    ))}
                  </Form.Group>
                  <Form.Group controlId="formUserLimit">
                    <Form.Label>Aktif Kullanıcı Limiti</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="200"
                      onChange={(e) => setForm({
                        ...form,
                        activeUserLimit: e.target.value
                      })}
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setModalVisible(false)}
                >
                  Kapat
                </Button>
                <Button variant="primary" onClick={handleAddCourse}>
                  Ekle
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={editModalVisible} onHide={() => setEditModalVisible(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Kurs Ekle</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group controlId="formCourseName">
                    <Form.Label>Kurs Adı</Form.Label>
                    <Form.Control
                      value={editForm.name}
                      type="text"
                      placeholder="Kurs Adı"
                      onChange={(e) => setEditForm({
                        ...editForm,
                        name: e.target.value
                      })}
                    />
                  </Form.Group>
                  <Form.Group controlId="icon">
                    <Form.Label>Logo Ekle</Form.Label>
                    <Form.Control type="file" accept="image/*" onChange={(e) => handleImageUpload(e, true)} />
                  </Form.Group>
                  <Form.Group controlId="editLanguages">
                    <Form.Label>Diller:</Form.Label>
                    {languages.map((lang) => (
                      <Form.Check
                        key={lang.id}
                        type="checkbox"
                        label={lang.label}
                        value={lang.id}
                        checked={editForm.languages && editForm.languages.includes(lang.id)}
                        onChange={(e) => handleCheckboxChange(e, "languages", true)}
                      />
                    ))}
                  </Form.Group>
                  <Form.Group controlId="editModules">
                    <Form.Label>Modüller:</Form.Label>
                    {modules.map((module) => (
                      <Form.Check
                        key={module.id}
                        type="checkbox"
                        label={module.label}
                        value={module.id}
                        checked={editForm.modules && editForm.modules.includes(module.id)}
                        onChange={(e) => handleCheckboxChange(e, "modules", true)}
                      />
                    ))}
                  </Form.Group>
                  <Form.Group controlId="editformUserLimit">
                    <Form.Label>Aktif Kullanıcı Limiti</Form.Label>
                    <Form.Control
                      value={editForm.activeUserLimit}
                      type="number"
                      placeholder="200"
                      onChange={(e) => setEditForm({
                        ...editForm,
                        activeUserLimit: e.target.value
                      })}
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setEditModalVisible(false)}
                >
                  Kapat
                </Button>
                <Button variant="primary" onClick={handleEditCourse}>
                  Düzenle
                </Button>
              </Modal.Footer>
            </Modal>
          </Container>
        </Col>
      </Row>
    </div>
  );
}
