import dashboard from './dashboard.json';
import questions from './questions.json';
import book from './book.json';
import headers from './headers.json';
import navbar from './navbar.json';
import error from './error.json';
import payment from './payment.json';

export default {
  dashboard,
  questions,
  book,
  headers,
  navbar,
  error,
  payment,
};
