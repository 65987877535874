import React, { useEffect, useReducer, useState, useRef } from 'react';
import { Container, Card, Form, Image, Button, Modal, Spinner } from 'react-bootstrap';
import { getQuestionById, getQuestionsIdsByExam } from '../../services/apiService';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaClipboardList } from 'react-icons/fa';

const Questions = () => {
  const { examId } = useParams();
  const { t } = useTranslation(['questions']);

  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const [questionIds, setQuestionIds] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [showCorrectAnswers, setShowCorrectAnswers] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [statics, setStatics] = useState({
    correct: 0,
    wrong: 0,
    note: 0
  });
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0); // Mevcut sorunun indeksi
  const [seconds, setSeconds] = useState(3599);
  // const [isVideo, setIsVideo] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const videoRef = useRef(null);

  useEffect(() => {
    document.title = t('headers:questions');
    fetchData();

    const intervalId = setInterval(() => {
      setSeconds(prevSeconds => {
        if (prevSeconds === 0) {
          clearInterval(intervalId); // Geri sayım tamamlandı, interval'i temizle
          return 0;
        } else {
          return prevSeconds - 1;
        }    
      });
    }, 1000)
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (questions[currentQuestionIndex]?.image && typeof questions[currentQuestionIndex]?.image === 'string' && questions[currentQuestionIndex]?.image.indexOf('data:video') === 0) {
      if (videoRef.current) {
        const savedVolume = localStorage.getItem('videoVolume');
        if (savedVolume !== null) {
          videoRef.current.volume = parseFloat(savedVolume);
        }
      }
    }
  }, [currentQuestionIndex, questions]);

  // useEffect(() => {
  //   // Sorunun video içerik taşıyıp taşımadığını kontrol et
  //   // if (questions[currentQuestionIndex]?.image && typeof questions[currentQuestionIndex]?.image === 'string' && questions[currentQuestionIndex]?.image.indexOf('data:video') === 0) {
  //   //   setIsVideo(true);
  //   // } else {
  //   //   setIsVideo(false);
  //   // }
  // }, [currentQuestionIndex, questions]);

  const fetchQuestion = async (questionId, index = null) => {
    const questionData = await getQuestionById(questionId);
    if(index === null) setQuestions((prevQuestions) => [...prevQuestions, questionData]);
    else setQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions];
      newQuestions[index] = questionData;
      return newQuestions;
    });
  }

  const fetchData = async () => {
    const questionsData = await getQuestionsIdsByExam(examId);
    setQuestionIds(questionsData);
    const question = await getQuestionById(questionsData[0].id);
    setQuestions((prevQuestions) => [...prevQuestions, question]);
    setIsLoading(false);
    // if(question.image && typeof question.image === 'string' && question.image.indexOf('data:video') === 0) {
    //   setIsVideo(true);
    //   setQuestions((prevQuestions) => [...prevQuestions, question]);
    //   setIsLoading(false);
    // } else {
    //   fetchAllQuestions(questionsData)
    // }
    // questionsData.forEach((question) => {
    //   fetchQuestion(question.id);
    // });
  }

  const fetchAllQuestions = async (questionData) => {
    questionData.forEach((question) => {
      fetchQuestion(question.id);
    });
    setIsLoading(false);
  }

  const handleOptionPress = (value, index) => {
    const newAnswers = [...answers];
    newAnswers[index] = value;
    setAnswers(newAnswers);
    setShowCorrectAnswers(true);
    forceUpdate();
  };

  const showAnswers = () => {
    let correctCounter = 0;
    let wrongCounter = 0;
    answers.forEach((answer, index) => {
      if (answer === questions[index].correctChoice) correctCounter++;
      else wrongCounter++;
    });
    setStatics({
      correct: correctCounter,
      wrong: wrongCounter,
      note: correctCounter * 2,
    });
    setModalVisible(true);
    setShowCorrectAnswers(true);
  };

  const handleClose = () => {
    setModalVisible(false);
  };

  const handleNextQuestion = async () => {
    if (currentQuestionIndex < questionIds.length - 1) {
      const nextIndex = currentQuestionIndex + 1;
      const nextQuestionId = questionIds[nextIndex].id;
      setCurrentQuestionIndex(nextIndex);
      setShowCorrectAnswers(false);
      if (!questions.some(q => q !== undefined && q.id === nextQuestionId)) {
        setIsLoading(true);
        await fetchQuestion(nextQuestionId, nextIndex);
        setIsLoading(false);
      }
    } else {
      setModalVisible(true);
    }
  };

  const handlePreviousQuestion = async () => {
    if (currentQuestionIndex > 0) {
      const previousIndex = currentQuestionIndex - 1;
      const previousQuestionId = questionIds[previousIndex].id;
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setShowCorrectAnswers(false);
      if (!questions.some(q => q !== undefined && q.id === previousQuestionId)) {
        setIsLoading(true);
        await fetchQuestion(previousQuestionId, previousIndex);
        setIsLoading(false);
      }
    }
  };

  const handleFormPress = async (index) => {
    setCurrentQuestionIndex(index)
    setShowCorrectAnswers(false)
    if(questions[index] === undefined) {
      setIsLoading(true)
      await fetchQuestion(questionIds[index].id, index)
      setIsLoading(false)
    }
  }

  const handleVolumeChange = () => {
    if (videoRef.current) {
      localStorage.setItem('videoVolume', videoRef.current.volume);
    }
  };

  const handleCopy = (event) => {
    event.preventDefault();
  };

  const getOptionName = (option) => {
    switch (option) {
      case 'A':
        return t('questions:A');
      case 'B':
        return t('questions:B');
      case 'C':
        return t('questions:C');
      case 'D':
        return t('questions:D');
      default:
        return '';
    }
  };

  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return (
    <Container>

      <div style={{ textAlign: 'center', fontWeight: 'bold', marginTop: 15 }}>
        <h3>
          {minutes.toString().padStart(2, '0')}:
          {remainingSeconds.toString().padStart(2, '0')}
        </h3>
      </div>

      <div className="d-flex justify-content-between mb-3 mt-3">
        <Button variant="outline-primary" onClick={handlePreviousQuestion} disabled={currentQuestionIndex === 0}>
          {t('questions:previous')}
        </Button>
        <div>{t('questions:question') + " " + (currentQuestionIndex + 1) + '/' + questionIds.length}</div>
        <Button disabled={isLoading} variant={currentQuestionIndex < questionIds.length - 1 || isLoading ? "outline-primary" : "outline-warning"} onClick={handleNextQuestion}>
          {currentQuestionIndex < questionIds.length - 1 || isLoading ? t('questions:next') : t('questions:finish')}
        </Button>
      </div>
      {/* <div className="d-flex justify-content-between mb-3 mt-3">
        <Button variant="outline-primary" onClick={() => setModalVisible(true)}>
          <FaClipboardList className="dashboard-icon mb-1" /> {t('questions:list')}
        </Button>
      </div> */}
      
      {isLoading && <Container className="text-center mt-5" style={{ width: 640, height: 360 }}>
        <Spinner style={{ marginTop: 20 }} animation="border" role="status">
          <span className="sr-only"></span>
        </Spinner>
        <h4 style={{ marginTop: 20 }}>{t('dashboard:loading')}</h4>
      </Container>}
      {questions.length > 0 && questions[currentQuestionIndex] !== undefined && (
        <Card className="my-4">
          <Card.Body>
            {questions[currentQuestionIndex]?.image && typeof questions[currentQuestionIndex]?.image === 'string' && questions[currentQuestionIndex]?.image.indexOf('data:image') === 0 && (
              <Image src={questions[currentQuestionIndex]?.image} style={{ maxHeight: 250 }} alt="Question Image" fluid className="mx-auto mt-3" />
            )}
            {questions[currentQuestionIndex]?.image && typeof questions[currentQuestionIndex]?.image === 'string' && questions[currentQuestionIndex]?.image.indexOf('data:video') === 0 && (
              <video 
                key={questions[currentQuestionIndex]?.image} 
                ref={videoRef}
                controls 
                width="640" 
                height="360" 
                autoPlay 
                className="mx-auto mt-3" 
                controlsList="nodownload"
                onVolumeChange={handleVolumeChange}
              >
                <source src={questions[currentQuestionIndex]?.image} type="video/mp4" />
                Tarayıcınız video etiketini desteklemiyor.
              </video>            
            )}
            <Card.Title><div onCopy={handleCopy} style={{ userSelect: 'none' }} dangerouslySetInnerHTML={{ __html: questions[currentQuestionIndex]?.questionText }} /></Card.Title>
            <Form>
              {['A','B','C','D'].map((option,index) => (
                <div style={{ flexDirection: 'row' }} key={option}>
                  <Form.Check
                    key={index}
                    type="radio"
                    id={`option-${option}`}
                    label={questions[currentQuestionIndex] !== undefined && questions[currentQuestionIndex][`option${option}`]?.indexOf('data:image') !== 0 
                      ? <div onCopy={handleCopy} style={{ marginLeft: 5, userSelect: 'none' }}>{getOptionName(option) + ') ' + questions[currentQuestionIndex][`option${option}`]}</div>
                      : <Image src={questions[currentQuestionIndex][`option${option}`]} style={{ height: 140 }} alt="Option Image" fluid className="mx-auto" />
                    }
                    name={`question-${questions[currentQuestionIndex]?.id}`}
                    checked={answers[currentQuestionIndex] === option}
                    onChange={() => handleOptionPress(option, currentQuestionIndex)}
                    style={{
                      backgroundColor:
                        answers[currentQuestionIndex] === option || questions[currentQuestionIndex]?.correctChoice === option
                          ? (showCorrectAnswers || answers[currentQuestionIndex] !== undefined)
                            ? option === questions[currentQuestionIndex]?.correctChoice
                              ? '#90EE90' // Doğru işaretlenen şık
                              : '#e71d36' // Yanlış işaretlenen şık
                            : questions[currentQuestionIndex][`option${option}`]?.indexOf('data:image') === 0 ? 'white' : '#f0f0f0'
                          : questions[currentQuestionIndex][`option${option}`]?.indexOf('data:image') === 0 ? 'white' : '#f0f0f0',
                      marginBottom: 10,
                      borderRadius: 5,
                      padding: 5,
                      marginLeft: 10
                    }}
                  />
                </div>
              ))}
            </Form>
          </Card.Body>
        </Card>
      )}

      <div className='text-center' style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(40px, 1fr))', gap: '1rem', justifyContent: 'center' }}>
        {questionIds.map((question, index) => (
          <Link style={{ textDecoration: 'none' }}>
            <Card 
              key={question?.id} 
              className="my-1" 
              onClick={() => handleFormPress(index)}
              style={{ 
                width: 50, 
                height: 50, 
                display:'flex', 
                justifyContent: 'center',
                backgroundColor: questions[index] !== undefined &&
                 answers[index] === questions[index]?.correctChoice 
                  ? '#90EE90' 
                  : answers[index] 
                    ? '#e71d36' 
                    : 'white',
              }}
            >
              <h5>{index + 1}</h5>
            </Card>
          </Link>
        ))}
      </div>

      <Modal show={modalVisible} onHide={handleClose}>
        <Modal.Body className='text-center' style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(75px, 1fr))', gap: '1rem', justifyContent: 'center' }}>
          {questionIds.map((question, index) => (
            <Link style={{ textDecoration: 'none' }}>
              <Card 
                key={question?.id} 
                className="my-4" 
                onClick={() => {
                  setCurrentQuestionIndex(index)
                  setModalVisible(false)
                  setShowCorrectAnswers(false)
                }}
                style={{ 
                  width: 75, 
                  height: 75, 
                  display:'flex', 
                  justifyContent: 'center',
                  backgroundColor: questions[index] !== undefined && 
                    answers[index] === questions[index]?.correctChoice 
                      ? '#90EE90' 
                      : answers[index] 
                        ? '#e71d36' 
                        : 'white',
                }}
              >
                <h4>{index + 1}</h4>
              </Card>
            </Link>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose}>
            {t('questions:review')}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Questions;
