import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import Sidebar from "../Sidebar/Sidebar";
import { editCourse, getCourseInfo } from "../../services/apiService";
import { useAuth } from "../../contexts/AuthContext";
import { ToastContainer, toast } from 'react-toastify';

export default function CourseInfo() {
  const { user } = useAuth();
  const [courseInfo, setCourseInfo] = useState({
    name: "",
    description: "",
    icon: "",
    email: "",
    phone: "",
    website: "",
    address: "",
    whatsapp: "",
    facebook: "",
    instagram: "",
    twitter: "",
    linkedin: "",
  });

  useEffect(() => {
    fetchCourseInfo();
  }, []);

  const fetchCourseInfo = async () => {
    const response = await getCourseInfo(user?.course.id);
    console.log(response)
    if (response) {
      setCourseInfo(response);
    }
  }

  const handleSubmit = async () => {
    const response = await editCourse(courseInfo, user?.course.id);
    if (response) {
      toast.success("Kurs bilgileri başarıyla güncellendi.");
      fetchCourseInfo();
    }
    window.location.reload();
  }

  const handleImageUpload = (e, isEdit) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCourseInfo((prevForm) => ({
          ...prevForm,
          icon: reader.result
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div style={{ flex: 1, height: "100vh", width: "100%" }}>
      <Row style={{ flex: 1, height: "100vh" }}>
        <Col sm={2} style={{ height: "100vh" }}>
          <Sidebar />
        </Col>
        <Col>
          <Container style={{ paddingLeft: 25, paddingRight: 25 }}>
            <Form className="mt-4">
              <Row style={{ marginBottom: 20 }}>
                <Col>
                  <Form.Group controlId="name">
                    <Form.Label>Kurs Adı</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Kurs Adı"
                      value={courseInfo.name}
                      onChange={(e) =>
                        setCourseInfo({
                          ...courseInfo,
                          name: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="phone">
                    <Form.Label>Telefon Numarası</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Örnek: 0555 555 55 55"
                      value={courseInfo.phone}
                      onChange={(e) =>
                        setCourseInfo({
                          ...courseInfo,
                          phone: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row style={{ marginBottom: 20 }}>
                <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Adres</Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    as="textarea"
                    value={courseInfo.address}
                    onChange={(e) =>
                      setCourseInfo({
                        ...courseInfo,
                        address: e.target.value,
                      })
                    }
                  />
                </Form.Group>
                </Col>
              </Row>
              <Row style={{ marginBottom: 20 }}>
                <Col>
                  <Form.Group controlId="email">
                    <Form.Label>E-Posta Adresi</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="E-Posta Adresi"
                      value={courseInfo.email}
                      onChange={(e) =>
                        setCourseInfo({
                          ...courseInfo,
                          email: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="facebook">
                    <Form.Label>Facebook Linki</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Facebook Linki"
                      value={courseInfo.facebook}
                      onChange={(e) =>
                        setCourseInfo({
                          ...courseInfo,
                          facebook: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row style={{ marginBottom: 20 }}>
                <Col>
                  <Form.Group controlId="twitter">
                    <Form.Label>Twitter Linki</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Twitter Linki"
                      value={courseInfo.twitter}
                      onChange={(e) =>
                        setCourseInfo({
                          ...courseInfo,
                          twitter: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="instagram">
                    <Form.Label>Instagram Linki</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Instagram Linki"
                      value={courseInfo.instagram}
                      onChange={(e) =>
                        setCourseInfo({
                          ...courseInfo,
                          instagram: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row style={{ marginBottom: 20 }}>
                <Col>
                  <Form.Group controlId="linkedin">
                    <Form.Label>LinkedIn Linki</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="LinkedIn Linki"
                      value={courseInfo.linkedin}
                      onChange={(e) =>
                        setCourseInfo({
                          ...courseInfo,
                          linkedin: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="whatsappPhone">
                    <Form.Label>Whatsapp Telefon Numarası</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Örnek: 905555555555"
                      value={courseInfo.whatsapp}
                      onChange={(e) =>
                        setCourseInfo({
                          ...courseInfo,
                          whatsapp: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row style={{ marginBottom: 20 }}>
                <Col>
                  <Form.Group controlId="icon">
                    <Form.Label>Logo Ekle</Form.Label>
                    <Form.Control type="file" accept="image/*" onChange={handleImageUpload} />
                  </Form.Group>
                </Col>
                <Col>
                 {courseInfo.icon !== '' && <img 
                    src={courseInfo.icon} 
                    className="d-inline-block align-top"
                    alt="Car Logo"
                    height='200'
                  />}
                </Col>
              </Row>
              <Row style={{ marginBottom: 20 }}>
                <Col className="text-end">
                  <Button
                    variant="primary"
                    className="mt-3"
                    onClick={() => handleSubmit()}
                  >
                    Düzenle
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>
        </Col>
      </Row>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={true}
      />
    </div>
  );
}
