import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { decodeToken, isAdmin } from "./constants";
import { useAuth } from "../contexts/AuthContext";

function TestRoute({ children }) {
  const { user } = useAuth();
  const [role, setRole] = useState(null);
  useEffect(() => {
    const decoded = decodeToken();
    setRole(decoded ? decoded.role : false)
  }, [user]);

  if (role === null) {
    return null;
  }

  if (!isAdmin(role)) {
    return <Navigate to="/" />;
  }

  return children;
}

export default TestRoute;
