import React, { useEffect, useState } from "react";
import { addPaymentPrice, deletePaymentPrice, editPaymentPrice, getPaymentPrices } from "../../services/apiService";
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  Modal,
} from "react-bootstrap";
import Sidebar from "../Sidebar/Sidebar";
import { useNavigate } from 'react-router-dom';
import { getPaymentType } from "../../helper/constants";

export default function PaymentPrices() {
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false)
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [paymentPrices, setPaymentPrices] = useState([])
  const [form, setForm] = useState({
    label: '',
    type: 0,
    price: 0,
  })
  const [editForm, setEditForm] = useState({
    label: '',
    type: 0,
    price: 0,
  })
  const [editId, setEditID] = useState(null)
  useEffect(() => {
    fetchPaymentPrices()
  }, [])

  const fetchPaymentPrices = async () => {
    const paymentPrices = await getPaymentPrices();
    setPaymentPrices(paymentPrices)
  }

  const handleAddPaymentPrice = async () => {
    const response = await addPaymentPrice(form)
    if(response) fetchPaymentPrices()
    setModalVisible(false)
  }

  const handleEditPaymentPrice = async () => {
    const response = await editPaymentPrice(editForm, editId)
    if(response) fetchPaymentPrices()
    setEditModalVisible(false)
  }

  const handleDelete = async (id) => {
    const response = await deletePaymentPrice(id)
    if(response) fetchPaymentPrices()
  }

  const handleEdit = (item) => {
    setEditForm({
      label: item.label,
      type: item.type,
      price: item.price
    })
    setEditID(item.id)
    setEditModalVisible(true)
  }

  return (
    <div style={{ flex: 1, height: "100vh", width: "100%" }}>
      <Row style={{ flex: 1, height: "100vh" }}>
        <Col sm={2} style={{ height: "100vh" }}>
          <Sidebar />
        </Col>
        <Col>
          <Container>
            <Container className="mt-3">
              <Row>
                <Col>
                  <div>
                    <Button
                      variant="primary"
                      onClick={() => setModalVisible(true)}
                    >
                      Ödeme Ekle
                    </Button>
                    <Table className="mt-3" striped bordered hover>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>İsim</th>
                          <th>Tipi</th>
                          <th>Fiyatı</th>
                          <th>İşlemler</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paymentPrices.map((item) => (
                          <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.label}</td>
                            <td>{getPaymentType(item.type)}</td>
                            <td>{item.price}</td>
                            <td>
                              <Button variant="info" size="sm" onClick={() => handleEdit(item)}>
                                Düzenle
                              </Button>{" "}
                              <Button variant="danger" size="sm" onClick={() => handleDelete(item.id)}>
                                Sil
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </Container>

            <Modal show={modalVisible} onHide={() => setModalVisible(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Ödeme Ekle</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group controlId="formPaymentPriceName">
                    <Form.Label>Ödeme Adı</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ödeme Adı"
                      onChange={(e) => setForm({
                        ...form,
                        label: e.target.value
                      })}
                    />
                  </Form.Group>
                  <Form.Group controlId="paymentType" className="mb-2">
                    <Form.Label>Tipi</Form.Label>
                    <Form.Select
                      name="type"
                      value={form.type}
                      onChange={(e) => {
                        setForm((prevForm) => ({
                          ...prevForm,
                          type: e.target.value
                        }));
                      }}
                      required
                    >
                      <option key={1} value={1}>Dil</option>
                      <option key={2} value={2}>Modül</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group controlId="formPaymentPrice">
                    <Form.Label>Fiyatı</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Fiyatı"
                      onChange={(e) => setForm({
                        ...form,
                        price: e.target.value
                      })}
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setModalVisible(false)}
                >
                  Kapat
                </Button>
                <Button variant="primary" onClick={handleAddPaymentPrice}>
                  Ekle
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={editModalVisible} onHide={() => setEditModalVisible(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Ödeme Düzenle</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group controlId="formPaymentPriceNameEdit">
                    <Form.Label>Ödeme Adı</Form.Label>
                    <Form.Control
                      value={editForm.label}
                      type="text"
                      placeholder="Ödeme Adı"
                      onChange={(e) => setEditForm({
                        ...editForm,
                        label: e.target.value
                      })}
                    />
                  </Form.Group>
                  <Form.Group controlId="paymentTypeEdit" className="mb-2">
                    <Form.Label>Tipi</Form.Label>
                    <Form.Select
                      name="type"
                      value={editForm.type}
                      onChange={(e) => {
                        setEditForm((prevForm) => ({
                          ...prevForm,
                          type: e.target.value
                        }));
                      }}
                      required
                    >
                      <option key={1} value={1}>Dil</option>
                      <option key={2} value={2}>Modül</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group controlId="formPaymentPriceEdit">
                    <Form.Label>Ödeme Fiyatı</Form.Label>
                    <Form.Control
                      value={editForm.price}
                      type="text"
                      placeholder="Ödeme Adı"
                      onChange={(e) => setEditForm({
                        ...editForm,
                        price: e.target.value
                      })}
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setEditModalVisible(false)}
                >
                  Kapat
                </Button>
                <Button variant="primary" onClick={handleEditPaymentPrice}>
                  Düzenle
                </Button>
              </Modal.Footer>
            </Modal>
          </Container>
        </Col>
      </Row>
    </div>
  );
}
