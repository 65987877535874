import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, Container, Col } from "react-bootstrap";
import { FaBook, FaCar, FaUsers, FaLanguage, FaFolder, FaInfoCircle } from "react-icons/fa";
import { PiExamDuotone, PiExamFill } from "react-icons/pi";
import { SiGoogleclassroom } from "react-icons/si";
import { HiDuplicate } from "react-icons/hi";
import { decodeToken, isAdmin, isSoru } from "../../helper/constants";
import { MdPayment } from "react-icons/md";

const Sidebar = () => {
  const [role, setRole] = useState(null);
  useEffect(() => {
    const decoded = decodeToken();
    setRole(decoded ? decoded.role : null);
  }, []);
  return (
    <Col className="bg-light sidebar">
      <Container
        fluid
        className="p-0"
        style={{ flex: 1, height: "100vh", marginLeft: 15 }}
      >
        <Navbar expand="lg">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="flex-column">
              <Nav.Link
                as={Link}
                to="/users"
                style={{ fontSize: 20, color: "black" }}
              >
                <FaUsers /> Kullanıcılar
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/courseinfo"
                style={{ fontSize: 20, color: "black" }}
              >
                <FaInfoCircle /> Kurs Bilgileri
              </Nav.Link>
              {isAdmin(role) && (
                <>
                  {/* <Nav.Link
                    as={Link}
                    to="/roles"
                    style={{ fontSize: 20, color: "black" }}
                  >
                    <MdHotelClass /> Roller
                  </Nav.Link> */}
                  <Nav.Link
                    as={Link}
                    to="/lessoncategories"
                    style={{ fontSize: 20, color: "black" }}
                  >
                    <HiDuplicate /> Ders Kategorileri
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/lessons"
                    style={{ fontSize: 20, color: "black" }}
                  >
                    <SiGoogleclassroom /> Dersler
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/sections"
                    style={{ fontSize: 20, color: "black" }}
                  >
                    <FaBook /> Kitaplar
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/languages"
                    style={{ fontSize: 20, color: "black" }}
                  >
                    <FaLanguage /> Diller
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/modules"
                    style={{ fontSize: 20, color: "black" }}
                  >
                    <FaFolder /> Modüller
                  </Nav.Link>
                </>
              )}
              {(isSoru(role) || isAdmin(role) ) && (
                <>
                  <Nav.Link
                    as={Link}
                    to="/courses"
                    style={{ fontSize: 20, color: "black" }}
                  >
                    <FaCar /> Kurslar
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/examcategories"
                    style={{ fontSize: 20, color: "black" }}
                  >
                    <PiExamDuotone /> Sınav Kategorileri
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/exams"
                    style={{ fontSize: 20, color: "black" }}
                  >
                    <PiExamFill /> Sınavlar
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/paymentprices"
                    style={{ fontSize: 20, color: "black" }}
                  >
                    <MdPayment /> Ödeme
                  </Nav.Link>
                </>
              )}
              {/* Diğer sayfa linkleri */}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </Col>
  );
};

export default Sidebar;
