import React, { useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";
import bgIcon from "../theme/assets/logo.png";
import orangeIcon from "../theme/assets/logo_turuncu.png";
import { Col, Row } from "react-bootstrap";
import { apiCourseRegister } from "../services/apiService";
import { ToastContainer, toast } from 'react-toastify';

function LoginForm() {
  const { t } = useTranslation(["headers"]);
  document.title = t("headers:login");

  const { login } = useAuth();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [courseName, setCourseName] = useState("");
  const [courseLogo, setCourseLogo] = useState("");
  const [error, setError] = useState("");
  const [isLogin, setIsLogin] = useState(true);
  const [userForm, setUserForm] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    password: "",
    roleId: 2,
    courseId: null,
    moduleId: 1
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await login(userName, password);
      if (response.isError) {
        const error = t(`error:${response.errorCode}`);
        setError(error);
      }
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await apiCourseRegister(courseName, courseLogo, userForm);
      if (response.isError) {
        const error = t(`error:${response.errorCode}`);
        setError(error);
      } else {
        toast.success("Kayıt işlemi başarıyla tamamlandı. Giriş yapabilirsiniz.");
        setIsLogin(true);
        setCourseName("");
        setCourseLogo("");
        setUserForm({
          firstName: "",
          lastName: "",
          userName: "",
          password: "",
          roleId: 2,
          courseId: null,
          moduleId: 1
        });
        setError("");
      }
    } catch (error) {
      console.error("Register error:", error);
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCourseLogo(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "100vh", padding: 0 }}
    >
      <div className="d-flex" style={{ width: "100%" }}>
        <div
          style={{
            flex: 1,
            backgroundColor: "#EA991A",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
          }}
        >
          <img
            className="spin-animation-y"
            src={bgIcon}
            style={{
              width: 500,
              height: 500,
              objectFit: "cover",
              position: "absolute",
            }}
          />
        </div>
        <div
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            width: "100%",
          }}
        >
          {/* <img src={bgIcon} alt="Background" style={{ width: 500, height: 500, objectFit: 'cover', position: 'absolute', zIndex: -1 }} /> */}
          <Card style={{ minWidth: 400, border: 0, position: "relative" }}>
            <Card.Img
              src={orangeIcon}
              style={{ opacity: 0.2, width: 500, height: 500 }}
            />
            <Card.ImgOverlay>
              {isLogin ? (
                <Card.Body style={{ marginTop: 100 }}>
                  <h3 className="text-center mb-4">Giriş Yap</h3>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group id="username">
                      <Form.Label>Kullanıcı Adı</Form.Label>
                      <Form.Control
                        type="username"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Şifre</Form.Label>
                      <Form.Control
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </Form.Group>
                    {error && (
                      <p className="text-center text-danger fw-bold">{error}</p>
                    )}
                    <Row>
                      <Col>
                        <Button className="w-100" type="submit">
                          Giriş
                        </Button>
                      </Col>
                      {/* <Col>
                        <Button
                          className="w-100"
                          onClick={() => setIsLogin(false)}
                        >
                          Kayıt Ol
                        </Button>
                      </Col> */}
                    </Row>
                  </Form>
                </Card.Body>
              ) : (
                <Card.Body>
                  <h3 className="text-center mb-4">Kayıt Ol</h3>
                  <Form onSubmit={handleRegisterSubmit}>
                    <Form.Group id="coursename">
                      <Form.Label>Kurs Adı</Form.Label>
                      <Form.Control
                        type="coursename"
                        value={courseName}
                        onChange={(e) => setCourseName(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Form.Group id="logo" className="mb-4">
                      <Form.Label>Kurs Logosu</Form.Label>
                      <Form.Control
                        type="file"
                        accept="image/*"
                        className="mt-2"
                        onChange={(e) => handleImageUpload(e)}
                        required
                      />
                    </Form.Group>
                    <Form.Group id="name">
                      <Form.Label>Kurs Yetkilisi Adı</Form.Label>
                      <Form.Control
                        type="name"
                        value={userForm.firstName}
                        onChange={(e) =>
                          setUserForm({
                            ...userForm,
                            firstName: e.target.value,
                          })
                        }
                        required
                      />
                    </Form.Group>
                    <Form.Group id="surname">
                      <Form.Label>Kurs Yetkilisi Soyadı</Form.Label>
                      <Form.Control
                        type="surname"
                        value={userForm.lastName}
                        onChange={(e) =>
                          setUserForm({
                            ...userForm,
                            lastName: e.target.value,
                          })
                        }
                        required
                      />
                    </Form.Group>
                    <Form.Group id="registerusername">
                      <Form.Label>
                        Kullanıcı Adı(Giriş yapmak için kullanılır)
                      </Form.Label>
                      <Form.Control
                        type="registerusername"
                        value={userForm.userName}
                        onChange={(e) =>
                          setUserForm({
                            ...userForm,
                            userName: e.target.value,
                          })
                        }
                        required
                      />
                    </Form.Group>
                    <Form.Group id="registerpassword" className="mb-4">
                      <Form.Label>
                        Şifre(Giriş yapmak için kullanılır)
                      </Form.Label>
                      <Form.Control
                        type="password"
                        value={userForm.password}
                        onChange={(e) =>
                          setUserForm({
                            ...userForm,
                            password: e.target.value,
                          })
                        }
                        required
                      />
                    </Form.Group>
                    {error && (
                      <p className="text-center text-danger fw-bold">{error}</p>
                    )}
                    <Row>
                      <Col>
                        <Button
                          className="w-100"
                          type="submit"
                        >
                          Kayıt Ol
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          className="w-100"
                          onClick={() => setIsLogin(true)}
                        >
                          Giriş Yap
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              )}
            </Card.ImgOverlay>
            {/* <img src={bgIcon} style={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute', top: 0, left: 0, zIndex: -1, opacity: 0.5 }} /> */}
          </Card>
        </div>
      </div>
      <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar={true} />
    </Container>
  );
}

export default LoginForm;
