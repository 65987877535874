import React, { createContext, useContext, useState, useEffect } from 'react';
import { apiLogin, apiRegister, getUserStatus } from '../services/apiService';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));

  useEffect(() => {
    if(user) localStorage.setItem('user', JSON.stringify(user));

  }, [user]);

  useEffect(() => {
    const checkUserStatus = async () => {
      const token = localStorage.getItem('token')
      if (!token) return;
      const response = await getUserStatus();
      if (response.isError || response.isActive !== true) logout();
      else setUser(response);
    };

    checkUserStatus();
  }, []);

  const login = async (email, password) => {
    const response = await apiLogin(email, password);
    if (!response.isError) {
      setToken(response.token);
      setUser(response.user)
      localStorage.setItem('token', response.token);    
    }
    return response;
  };

  const logout = () => {
    setToken(null);
    setUser(null)
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('selectedLanguage');
  };

  const isAuthenticated = !!token;

  return (
    <AuthContext.Provider value={{ token, login, logout, isAuthenticated, user }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
