import React, { useEffect, useState } from 'react';
import { getPartsbySection } from '../../services/apiService';
import { Card, Container, Row, Col, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

export default function SectionParts() {
  const { t } = useTranslation(['headers']);
  const { sectionId } = useParams();
  const navigate = useNavigate();

  const [sectionParts, setSectionParts] = useState([]);
  const [hoveredCardIndex, setHoveredCardIndex] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state'i ekle, başlangıçta true olarak ayarla

  useEffect(() => {
    document.title = t('headers:book-parts');
    const fetchSectionParts = async () => {
      try {
        const parts = await getPartsbySection(sectionId);
        setSectionParts(parts);
      } catch (error) {
        console.error('API hatası:', error);
        // Hata durumunda uygun bir hata işleme mekanizması eklenebilir
      } finally {
        setLoading(false); // API yanıtı geldikten sonra loading'i false yap
      }
    };
    fetchSectionParts();
  }, [sectionId]);

  return (
    <Container>
      {loading ? ( // loading state'ine göre yükleme göstergesini veya bileşenleri göster
        <div className="d-flex justify-content-center mt-5" style={{ flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
          <Spinner animation="border" role="status">
            <span className="sr-only"></span>
          </Spinner>
          <h4 style={{ marginTop: 20 }}>{t('dashboard:loading')}</h4>
        </div>
      ) : (
        <Row className='mt-3'>
          {sectionParts.map((sectionPart, index) => (
            <Col
              key={index}
              md={3}
              className="mb-4"
              onMouseEnter={() => setHoveredCardIndex(index)}
              onMouseLeave={() => setHoveredCardIndex(null)}
            >
              <Card
                style={{
                  transform: hoveredCardIndex === index ? 'scale(1.05)' : 'scale(1)',
                  boxShadow: hoveredCardIndex === index ? '0 0 10px rgba(0, 0, 0, 0.5)' : '0 5px 5px rgba(0, 0, 0, 0.5)',
                  backgroundColor: 'white',
                  borderRadius: 5,
                  alignSelf: 'center',
                  borderBottomColor: "#03045e",
                  borderBottomWidth: 5
                }}
                onClick={() => navigate(`/audio/${sectionPart.id}`)}
              >
                <Card.Img variant="top" src={sectionPart.coverImage} alt={sectionPart.name} />
                <Card.Body>
                  <Card.Title className="text-center" style={{ fontFamily: (i18next.language === 'ar' || i18next.language === 'fa' || i18next.language === 'ru') ? '' : 'Anton' }}>{sectionPart.name}</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </Container>
  );
}
