import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { USER_ROLES, decodeToken, isAdmin, isSoru } from "./constants";
import { useAuth } from "../contexts/AuthContext";

function UsersRoute({ children }) {
  const { user } = useAuth();
  const [role, setRole] = useState(null);

  useEffect(() => {
    const decoded = decodeToken();
    setRole(decoded ? decoded.role : false)
  }, [user]);

  if (role === null) {
    return null;
  }

  if (!isAdmin(role) && role !== USER_ROLES.COURSEADMIN && !isSoru(role)) {
    return <Navigate to="/" />;
  }

  return children;
}

export default UsersRoute;
