import React, { useState, useEffect, useRef } from 'react';
import AudioPlayer from 'react-audio-player';
import { getSectionPartAudio, getSectionPartImages, getSectionPartImagesIndex } from '../../services/apiService';
import { useParams, useLocation } from 'react-router-dom';
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './Audio.css';

export default function Audio() {
  const { sectionPartId } = useParams();
  const { t } = useTranslation(['book']);

  const [sesDosyasiYolu, setSesDosyasiYolu] = useState('');
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isZoomed, setIsZoomed] = useState(false);
  const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });
  const [images, setImages] = useState([]);
  const volume = useRef(0.5);
  
  useEffect(() => {
    document.title = t('headers:audio');
    fetchAudio()
    fetchImages(0)  
  }, [sectionPartId]);
  
  const fetchAudio = async () => {
    const url = await getSectionPartAudio(sectionPartId);
    setSesDosyasiYolu(url);
  }

  const fetchImages = async (index) => {
    while (true) {
      const response = await getSectionPartImagesIndex(sectionPartId, index);
      if (!response.image) {
        console.error('Görüntüler alınamadı veya geçersiz format.');
        break;
      }
      setImages(prevImages => [...prevImages, response.image]); 
      if (response.length <= index + 1) {
        break; // Döngüyü sonlandır
      }
  
      index++; // Sonraki indekse geç
    }
  };
  

  // const fetchImages = async () => {
  //   const response = await getSectionPartImages(sectionPartId);
  //   setImages(response.images);
  // }

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = (e.clientX - left) / width;
    const y = (e.clientY - top) / height;
    setZoomPosition({ x, y });
  };

  const handleImageChange = (direction) => {
    if (direction === 'next') {
      setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    } else if (direction === 'prev') {
      setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    }
  };

  const preventDownload = (e) => {
    e.preventDefault();
  };

  return (
    <Container className="text-center mt-5">
      {images.length >= 1 ? <Row>
          <Col md={12}>
            <div
              className={`image-container ${isZoomed ? 'zoomed' : ''}`}
              onMouseDown={() => setIsZoomed(!isZoomed)}
              // onMouseLeave={() => setIsZoomed(false)}
              onMouseMove={handleMouseMove}
            >
              <img
                src={images[currentImageIndex]}
                alt={`Resim ${currentImageIndex + 1}`}
                className={`zoomable-image ${isZoomed ? 'zoomed' : ''}`}
                style={{ maxWidth: '100%', width: 'auto', maxHeight: '75vh', objectFit: 'contain', transformOrigin: `${zoomPosition.x * 100}% ${zoomPosition.y * 100}%` }}
                onContextMenu={preventDownload} 
                onLongPress={preventDownload}
                onDragStart={preventDownload}
                draggable="false"
              />
            </div>
          </Col>
        </Row> :
        <Container className="text-center mt-5" style={{ height: '75vh' }}>
          <Spinner animation="border" role="status">
            <span className="sr-only"></span>
          </Spinner>
          <h4 style={{ marginTop: 20 }}>{t('dashboard:loading')}</h4>
        </Container>
      }
      {images.length > 1 && <Row className='mb-3'>
        <Col md={6} className="d-flex justify-content-end">
          <Button disabled={currentImageIndex === 0 ? true : false} variant="outline-primary" onClick={() => handleImageChange('prev')} className="align-self-center">
            {t('book:prev')}
          </Button>
        </Col>
        <Col md={6} className="d-flex justify-content-start">
          <Button variant="outline-primary" onClick={() => handleImageChange('next')} className="align-self-center">
            {currentImageIndex === images.length - 1 ? t('book:toFirst') : t('book:next')}
          </Button>
        </Col>
      </Row>}
      <Row>
        <Col md={12}>
          <AudioPlayer
            src={sesDosyasiYolu}
            autoPlay={true}
            controls
            controlsList='nodownload'
            className="mx-auto"
            volume={volume.current}
            onVolumeChanged={(e) => volume.current = e.target.volume}
          />
        </Col>
      </Row>
    </Container>
  );
}