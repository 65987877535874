import CryptoJS from "crypto-js";

export async function createPayment(paymentData) {
  const rand = Math.floor(Math.random() * 1000000);
  const apiKey = process.env.REACT_APP_PAYMENT_API_KEY;
  const secretKey = process.env.REACT_APP_PAYMENT_SECRET_KEY;
  const apiUrl = process.env.REACT_APP_PAYMENT_URL;

  function formatObject(obj) {
    if (Array.isArray(obj)) {
      return `[${obj.map(item => formatObject(item)).join(',')}]`;
    } else {
      const formattedValues = Object.entries(obj).map(([key, value]) => {
        if (typeof value === 'object') {
          return `${key}=${formatObject(value)}`;
        } else {
          return `${key}=${value}`;
        }
      });
      return `[${formattedValues.join(',')}]`;
    }
  }

  const requestString = formatObject(paymentData);
  const pkiString = CryptoJS.enc.Base64.stringify(CryptoJS.SHA1(apiKey + rand + secretKey + requestString))
  return await fetch(`${apiUrl}/payment/auth`, {
    method: 'POST',
    headers: {
      'Accept': '*/*',
      'Accept-Encoding': 'gzip, deflate, br',
      'Connection': 'keep-alive',
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': "IYZWS " + apiKey + ":" + pkiString,
      'x-iyzi-rnd': rand,
    },
    body: JSON.stringify(paymentData),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      console.error('Error:', error);
    });
}