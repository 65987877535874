import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'

function PrivateRoute({ children }) {
  const { isAuthenticated, user } = useAuth();
  
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  if (user && user?.course.isPayed === false) {
    return <Navigate to="/pay" />;
  }

  return children;
}

export default PrivateRoute;
