import React from 'react'
import { useAuth } from "../../contexts/AuthContext";
import { Button } from 'react-bootstrap';
import { Container, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const PayPage = () => {
  const { logout } = useAuth();
  const { t } = useTranslation(['payment']);

  return (
    <Container>
      <Card style={{ marginTop: 20, borderRadius: 10 }}>
        <Card.Body>
          <Card.Text>
            {t('payment:payment')}
          </Card.Text>
          <Link to="/payment" className="btn btn-primary">
            {t('payment:payment')}
          </Link>
        </Card.Body>
      </Card>
      <Button onClick={logout}>Logout</Button>
    </Container>
  )
}

export default PayPage